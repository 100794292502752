<template>
  <section id="home-section7" class="section">
    <div class="section__container">
      <div class="section__content">
        <div class="section__content--block">
          <div class="section__content--title">
            1 таблетка Алерону –<br>
            <span class="part">24 години д<span style="letter-spacing: 0.08em">ії</span> проти алерг<span style="letter-spacing: 0.08em">ії</span><sup>*2,3</sup></span>
          </div>

          <div class="section__content--text">
            Симптоматичне лікування алергічного риніту (зокрема цілорічного) та
            кропив’янки<sup>1</sup>

            <!-- Впливає на перебіг алергічних реакцій<sup>1</sup> -->
          </div>

          <div class="section__content--actions">
            <a target="_blank" href="https://tabletki.ua/%D0%90%D0%BB%D0%B5%D1%80%D0%BE%D0%BD/" class="btn" onclick="gtag('event', 'click', {  'event_category': 'Де придбати',  'event_label':' 1 таблетка Алерону – 24 години дії проти алергії'})">
              Де придбати
              <span class="btn__icon btn__icon--arrow"></span>
            </a>
          </div>
        </div>

        <div class="section__bg"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "section-7",
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.section {
  overflow: hidden;
  padding: 0 20px;
  margin-top: 100px;
  padding-top: 50px;

  @include gt-md {
    padding: $headerSize 20px $footerSize;
  }

  &__container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: $contentWidthSm;

    @include gt-md {
      max-width: $contentWidthLg;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__content {
    position: relative;
    // width: 100%;
    height: auto;
    // max-height: 450px; /* ---- */
    // border: 1px solid #00000020; /* ---- eef233 */

    // ---
    padding: 0 15px;

    &--block {
      position: relative;
      z-index: 11;

      @include gt-md {
        padding-bottom: 100px;
        width: 500px;
      }
    }

    &--title {
      @include Ubuntu-700;
      font-size: 19px;
      line-height: 120%;



      @media screen  and (min-width: 390px){
        font-size: 21px;
      }

      @include gt-md {
        font-size: 32px;
      }

      .part {
        @include gt-md {
          display: block;
        }
      }
    }

    &--text {
      margin-top: 22px;
      padding-right: 30px;

      font-size: 13px;
      line-height: 130%;
      font-feature-settings: "pnum" on, "lnum" on;

      @media screen  and (min-width: 390px){
        font-size: 15px;
      }

      @include gt-md {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &--actions {
      margin-top: 300px;

      @include gt-md {
        margin-top: 70px;
      }
    }
  }

  &__bg {
    z-index: 10;
    position: absolute;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;

    background-image: url("@/assets/home_icon/packs-mob-3-min.png");
    width: 535px;
    height: 445px;
    top: 40px;
    left: -154px;

    @include gt-md {
      background-image: url("@/assets/home_icon/packs-des-3-min.png");
      width: 996px;
      height: 809px;
      top: -220px;
      left: 400px;
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  min-width: 185px;
  padding: 12px 20px;
  background: #eef233;
  border-radius: 41px;
  text-decoration: none;

  font-weight: 600;
  font-size: 13px;
  line-height: 130%;

  color: #0d783d;

  @media screen  and (min-width: 390px){
    font-size: 15px;
  }

  @include gt-md {
    padding: 13px 20px;
    min-width: 220px;
    font-size: 18px;
    line-height: 24px;
  }
  @media screen and (min-width: 1024px) {
    &:hover {
      background-color: #ffffff;
    }
  }

  &:active {
    background-color: #ffffff;
  }

  &__icon {
    margin-left: 20px;

    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;

    width: 14px;
    height: 14px;

    &--arrow {
      width: 16px;
      min-height: 16px;
      background-image: url("@/assets/icon/arrow-right.svg");
    }
  }
}
</style>
