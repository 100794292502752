<template>
  <full-scroll selector=".fullscreen" :breakpoint="1024" class="wrap">
    <!-- 
    <section id="section1" class="fullscreen section">
      <div class="section__container">
        <div class="section__content">
          <div style="height: 420px">1</div>
        </div>
      </div>
    </section>
    -->

    <section1 ref="section1" class="fullscreen"></section1>
    <section2 ref="section2" class="fullscreen"></section2>
    <section3 ref="section3" class="fullscreen"></section3>
    <section4 ref="section4" class="fullscreen"></section4>
    <section5 ref="section5" class="fullscreen"></section5>
    <section6 ref="section6" class="fullscreen"></section6>
    <section7 ref="section7" class="fullscreen"></section7>
  </full-scroll>
</template>

<script>
import FullScroll from "@/components/full-scroll";
import section1 from "@/components/home/section-1";
import section2 from "@/components/home/section-2";
import section3 from "@/components/home/section-3";
import section4 from "@/components/home/section-4";
import section5 from "@/components/home/section-5";
import section6 from "@/components/home/section-6";
import section7 from "@/components/home/section-7";

export default {
  components: {
    FullScroll,
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
    section7,
  },

  data() {
    const vue = this;

    return {

      trigger25: true,
      trigger50: true,
      trigger75: true,
      trigger90: true,
    }
  },

  mounted() {
    document.addEventListener('scroll', () => {

      var h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

      var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);

      if (percent >= 25 && percent < 50 && this.trigger25) {
        this.trigger25 = false
        gtag('event', 'scroll', {'event_category': 'moreThan25%', 'event_label': "Головна сторінка"})
      } else if (percent >= 50 && percent < 75 && this.trigger50) {
        this.trigger50 = false
        gtag('event', 'scroll', {'event_category': 'moreThan50%', 'event_label': "Головна сторінка"})
      } else if (percent >= 75 && percent < 90 && this.trigger75) {
        this.trigger75 = false
        gtag('event', 'scroll', {'event_category': 'moreThan75%', 'event_label': "Головна сторінка"})
      } else if (percent >= 90 && this.trigger90) {
        this.trigger90 = false
        gtag('event', 'scroll', {'event_category': 'moreThan90%', 'event_label': "Головна сторінка"})
      }

      return false
    });
  }
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.wrap {
  overflow: hidden;
}

// .section {
//   overflow-x: hidden;
//   padding: 0 20px;
//   padding-top: $headerSize;

//   @include gt-md {
//     padding: $headerSize 20px $footerSize;
//   }

//   &__container {
//     position: relative;
//     margin: 0 auto;
//     padding: 20px 0;
//     width: 100%;
//     max-width: 360px;

//     @include gt-sm {
//       max-width: 720px;
//     }

//     @include gt-md {
//       max-width: 1220px;
//       height: 100%;
//       display: flex;
//       align-items: center;
//     }
//   }

//   &__content {
//     width: 100%;
//     height: 100%;
//     max-height: 450px; /* ---- */
//     border: 1px solid #00000020; /* ---- eef233 */
//   }
// }
</style>
