<template>
  <div class="top-menu" :class="[{ active: scroll > 1 }]">
    <div class="visible">
      <div @click="open = !open" class="visible__menu-mobile">
        <svg viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 11.2856V9.28564H27V11.2856H1Z" fill="#EEF233"/>
          <path d="M27 19.2856H1V17.2856H27V19.2856Z" fill="#EEF233"/>
        </svg>
      </div>

      <router-link to="/" class="visible__logo"
                   onclick="gtag('event', 'click', {  'event_category': 'Клік на лого з переходом на головну',  'event_label':'menu'})">
        <svg
            viewBox="0 0 100 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_601_4092)">
            <path
                d="M17.5084 21.4309H13.5066C13.369 21.4166 13.2399 21.3576 13.139 21.263C13.038 21.1684 12.9708 21.0434 12.9475 20.907L11.9349 16.2493H6.09295L5.07599 20.907C5.04692 21.0469 4.97327 21.1735 4.86611 21.268C4.75896 21.3625 4.62406 21.4196 4.48166 21.4309H0.48867C0.426922 21.4378 0.364428 21.4314 0.305294 21.4124C0.246159 21.3934 0.191727 21.362 0.145571 21.3204C0.0994142 21.2789 0.0625804 21.228 0.037492 21.1711C0.0124037 21.1143 -0.000369013 21.0528 8.11423e-06 20.9907C8.11423e-06 20.9555 0.0352215 20.9202 0.0352215 20.8498L4.30557 3.16531C4.93952 0.431414 7.07468 0.0131836 9.03376 0.0131836C10.9928 0.0131836 13.062 0.431414 13.7267 3.16531L17.9663 20.8498C17.9663 20.9202 18.0015 20.9555 18.0015 20.9907C18.0019 21.0532 17.989 21.1151 17.9637 21.1723C17.9383 21.2295 17.9011 21.2806 17.8545 21.3223C17.8078 21.3639 17.7528 21.3951 17.6931 21.4139C17.6335 21.4326 17.5705 21.4384 17.5084 21.4309ZM9.03376 4.02818C8.7564 4.02818 8.68597 4.20428 8.59351 4.46842L6.90299 12.3267H11.1425L9.38155 4.48163C9.35073 4.20428 9.28029 4.02818 9.03376 4.02818Z"
                fill="white"
            />
            <path
                d="M26.2735 8.92805L23.544 20.8146C23.5091 20.9994 23.408 21.1652 23.2598 21.2811C23.1116 21.3969 22.9262 21.4549 22.7384 21.4441H19.5511C19.4611 21.4477 19.3714 21.4331 19.2872 21.4014C19.203 21.3697 19.126 21.3214 19.0608 21.2595C18.9955 21.1975 18.9433 21.1231 18.9073 21.0406C18.8713 20.9582 18.8522 20.8693 18.8511 20.7793C18.8663 20.7214 18.878 20.6626 18.8863 20.6033L22.2806 7.43564C22.8088 5.26525 24.7327 4.79419 26.4144 4.79419C28.0961 4.79419 29.9143 5.14198 30.5087 7.43564L33.9425 20.6429V20.7838C33.9398 20.8763 33.9184 20.9673 33.8796 21.0514C33.8408 21.1355 33.7853 21.2108 33.7167 21.2729C33.648 21.335 33.5675 21.3826 33.48 21.4127C33.3924 21.4429 33.2997 21.4551 33.2073 21.4485H30.02C29.839 21.4473 29.6638 21.3843 29.5237 21.2697C29.3835 21.1552 29.2868 20.9961 29.2496 20.819L26.4849 8.93245C26.4056 8.72114 26.3 8.72114 26.2735 8.92805Z"
                fill="white"
            />
            <path
                d="M49.1662 14.7085H40.062V14.779C40.062 16.3902 40.7267 17.8607 43.0028 17.8607C44.7902 17.8607 47.3128 17.7198 48.4662 17.6141H48.5366C48.8536 17.6141 49.0957 17.755 49.0957 18.0544V20.2292C49.0957 20.6694 48.9945 20.8939 48.5014 20.9996C46.5918 21.4861 44.6252 21.7127 42.655 21.6731C39.1859 21.6731 35.5099 19.8505 35.5099 14.3519V12.3576C35.5099 7.77032 38.1733 4.87354 42.7254 4.87354C47.3832 4.87354 49.7693 8.06088 49.7693 12.3576V14.0041C49.7649 14.462 49.5184 14.7085 49.1662 14.7085ZM42.6902 8.58037C41.1142 8.58037 40.0488 9.66336 40.0488 11.7325V11.8382H45.2304V11.7325C45.2436 9.63254 44.2663 8.58037 42.6902 8.58037Z"
                fill="white"
            />
            <path
                d="M59.3225 21.6729C58.5494 21.6577 57.7777 21.5989 57.0112 21.4968V26.4011C57.0068 26.5573 56.9427 26.7059 56.8322 26.8164C56.7217 26.9269 56.5731 26.991 56.4169 26.9954H52.9874C52.8307 26.991 52.6816 26.9272 52.5704 26.8167C52.4592 26.7063 52.3942 26.5577 52.3887 26.4011V6.44496C52.3887 6.00472 52.4943 5.7802 53.2692 5.6041C55.2548 5.13458 57.2866 4.88798 59.3269 4.8689C63.9494 4.8689 66.5072 7.56317 66.5072 12.1505V14.3913C66.5028 18.873 64.1563 21.6729 59.3225 21.6729ZM59.3225 8.68139C58.5499 8.68087 57.778 8.72792 57.0112 8.82226V17.7107C57.7767 17.8219 58.5489 17.8807 59.3225 17.8868C61.3564 17.8868 61.8803 16.3812 61.8803 14.3649V12.1241C61.8803 10.1562 61.3564 8.68579 59.3225 8.68579V8.68139Z"
                fill="white"
            />
            <path
                d="M75.7479 21.6732C71.1254 21.6732 68.6028 18.8028 68.6028 14.2815V12.2872C68.6028 7.73511 71.1254 4.86475 75.7479 4.86475C80.3704 4.86475 82.893 7.73511 82.893 12.2872V14.2815C82.893 18.8072 80.3704 21.6732 75.7479 21.6732ZM75.7479 8.78729C74.1014 8.78729 73.1901 9.97594 73.1901 12.2872V14.2815C73.1901 16.5928 74.1014 17.8034 75.7479 17.8034C77.3944 17.8034 78.3057 16.6104 78.3057 14.2815V12.2872C78.3057 9.98034 77.3944 8.7917 75.7479 8.7917V8.78729Z"
                fill="white"
            />
            <path
                d="M99.0367 21.431H95.6381C95.5583 21.4376 95.4781 21.4268 95.4029 21.3993C95.3278 21.3718 95.2596 21.3283 95.203 21.2717C95.1464 21.2151 95.1028 21.1469 95.0753 21.0717C95.0478 20.9966 95.0371 20.9164 95.0437 20.8366V14.8846H90.0734V20.8366C90.0834 20.9144 90.076 20.9934 90.0517 21.068C90.0274 21.1425 89.9868 21.2107 89.9328 21.2676C89.8789 21.3245 89.8129 21.3687 89.7398 21.397C89.6666 21.4252 89.5881 21.4368 89.5099 21.431H86.0804C86.0003 21.4383 85.9196 21.428 85.8439 21.4008C85.7682 21.3736 85.6994 21.3302 85.6424 21.2735C85.5853 21.2169 85.5413 21.1484 85.5136 21.0729C85.4858 20.9974 85.4749 20.9168 85.4817 20.8366V5.70994C85.4717 5.62892 85.4803 5.54669 85.5069 5.46951C85.5335 5.39232 85.5773 5.32221 85.635 5.26449C85.6927 5.20677 85.7628 5.16296 85.84 5.1364C85.9172 5.10984 85.9994 5.10123 86.0804 5.11121H89.5099C89.8269 5.11121 90.0734 5.25208 90.0734 5.70994V11.0325H95.0437V5.70994C95.0339 5.62933 95.0424 5.54754 95.0688 5.47072C95.0951 5.3939 95.1385 5.32405 95.1957 5.26642C95.2529 5.20879 95.3224 5.16488 95.399 5.138C95.4757 5.11111 95.5574 5.10195 95.6381 5.11121H99.0367C99.1174 5.10195 99.1991 5.11111 99.2757 5.138C99.3524 5.16488 99.4219 5.20879 99.4791 5.26642C99.5363 5.32405 99.5797 5.3939 99.606 5.47072C99.6323 5.54754 99.6409 5.62933 99.6311 5.70994V20.8366C99.6377 20.9164 99.6269 20.9966 99.5994 21.0717C99.5719 21.1469 99.5284 21.2151 99.4718 21.2717C99.4152 21.3283 99.347 21.3718 99.2718 21.3993C99.1967 21.4268 99.1165 21.4376 99.0367 21.431Z"
                fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_601_4092">
              <rect width="99.631" height="27" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </router-link>

      <div class="visible__btn-wrapper" v-bind:class="[{ active: open }]">
        <router-link onclick="gtag('event', 'click', {  'event_category': 'Алерон в дії',  'event_label':'menu desk'})"
                     to="/#alleron-in-action"
                     class="visible__item single"
                     active-class="active"
        >Алерон в д<span style="letter-spacing: 0.08em">ії</span></router-link>

        <router-link

            onclick="gtag('event', 'click', {  'event_category': 'Прояви алергії',  'event_label':'menu desk'})"
            to="/#home-section3"
            class="visible__item single"

            active-class="active"
            :class="[{ active: ['allergy'].includes($route.name) },{ active: ['#home-section4'].includes($route.hash) }]"
        >
          Прояви алерг<span style="letter-spacing: 0.08em">ії</span>
        </router-link>

        <!--        <div class="visible__item drop">-->
        <!--          <div-->
        <!--              v-bind:class="[{ active: $route.path === '/allergy'}, { active: ['#home-section3', '#home-section4'].includes(-->
        <!--                $route.hash)-->
        <!--              }]"-->
        <!--              class="drop-show"-->
        <!--          >-->
        <!--            {{ dropName }}-->
        <!--          </div>-->
        <!--          <div class="drop-hide">-->
        <!--            <router-link-->
        <!--                to="/#home-section3"-->
        <!--                v-text="'Прояви алергії'"-->
        <!--                class="drop-hide__item"-->
        <!--                active-class="active"-->
        <!--            ></router-link>-->

        <!--            <router-link-->
        <!--                to="/allergy#how-does-an-allergy-occur"-->
        <!--                v-text="'Як виникає алергія?'"-->
        <!--                class="drop-hide__item"-->
        <!--                active-class="active"-->
        <!--            ></router-link>-->

        <!--            <router-link-->
        <!--                to="/allergy#what-factors"-->
        <!--                v-text="'Які чинники підвищують ризик алергії?'"-->
        <!--                class="drop-hide__item"-->
        <!--                active-class="active"-->
        <!--            ></router-link>-->

        <!--            <router-link-->
        <!--                to="/allergy#rhinitis-and-urticaria"-->
        <!--                v-text="'Риніт та кропив’янка — найпоширеніші прояви алергії'"-->
        <!--                class="drop-hide__item"-->
        <!--                active-class="active"-->
        <!--            ></router-link>-->
        <!--          </div>-->
        <!--        </div>-->

        <router-link onclick="gtag('event', 'click', {  'event_category': 'Цікаві факти',  'event_label':'menu desk'})"
                     to="/#interesting-facts"
                     class="visible__item single"
                     v-text="'Цікаві факти'"
                     active-class="active"
        ></router-link>

        <router-link onclick="gtag('event', 'click', {  'event_category': 'Поради',  'event_label':'menu desk'})"
                     to="/#advices"
                     class="visible__item single"
                     v-text="'Поради'"
                     active-class="active"
                     :class="{ active: ['#home-section7'].includes($route.hash) }"
        ></router-link>

        <a target="_blank"
           href="https://tabletki.ua/%D0%90%D0%BB%D0%B5%D1%80%D0%BE%D0%BD/"
           class="visible__item background"
           onclick="gtag('event', 'click', {  'event_category': 'Де придбати',  'event_label':'header desk'})"

        >
          Де придбати
          <span class="icon arrow-right"></span>
        </a>
      </div>
    </div>

    <div class="hide" v-show="open">
      <div class="hide__close" @click="open = !open">
        <svg width="28" height="29">
          <use xlink:href="@/assets/defs.svg#close"></use>
        </svg>
      </div>

      <a target="_blank"
         href="https://tabletki.ua/%D0%90%D0%BB%D0%B5%D1%80%D0%BE%D0%BD/"
         class="hide__item background"
         onclick="gtag('event', 'click', {  'event_category': 'Де придбати',  'event_label':'header mob'})"
      >
        Де придбати
        <span class="icon arrow-right"></span>
      </a>

      <div class="hide__list-wrapper">
        <router-link
            v-for="item in navList"
            :key="item.label"
            :to="item.to"
            class="hide__list-item"
            :class="item.class"
            custom
            v-slot="{ href, route, navigate, isActive, isExactActive }"
            :onclick="`gtag('event', 'click', {  'event_category': '${item.label}',  'event_label':'header mob'})`"

        >

          <a
              :href="href"
              :class="{ active: isActive || isExactActive }"
              @click="(e) => { navigate(e); open = !open }"
              v-html="item.label"
          >

            <svg v-if="item.label === 'Поради'"
                 class="hide__round-animation"
                 width="271"
                 height="271"
                 viewBox="0 0 271 271"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
            >
              <g class="animation">
                <path
                    d="M66.6077 75.0571L67.6404 73.8717L82.6548 78.9959L81.3504 80.4932L76.9648 78.9771L72.9156 83.6251L75.032 87.7459L73.7276 89.2432L66.6077 75.0571ZM75.6012 78.3379L68.7461 75.8776L72.0684 82.3932L75.6012 78.3379Z"
                    fill="#EEF233"
                />
                <path
                    d="M85.3113 77.073L84.3275 75.7682C84.6138 75.5523 84.8131 75.2898 84.9254 74.9806C85.0404 74.6521 85.0532 74.2453 84.9637 73.7601C84.8659 73.2638 84.63 72.6646 84.2558 71.9622C83.8844 71.2405 83.3458 70.3772 82.6399 69.3723L80.8586 66.8035L86.7222 62.3826L93.2228 71.0045L91.7693 72.1004L86.24 64.7668L83.1183 67.1205L84.094 68.4832C84.9382 69.6947 85.578 70.7494 86.0133 71.6475C86.4486 72.5455 86.6988 73.324 86.7639 73.983C86.829 74.6421 86.7326 75.2243 86.4746 75.7297C86.2276 76.2267 85.8399 76.6745 85.3113 77.073Z"
                    fill="#EEF233"
                />
                <path
                    d="M103.135 65.9456C102.4 66.2959 101.666 66.4858 100.931 66.5154C100.203 66.5265 99.5051 66.4087 98.8373 66.162C98.1761 65.8968 97.5755 65.5186 97.0356 65.0275C96.5082 64.5304 96.0812 63.9396 95.7546 63.255C95.3153 62.3339 95.1261 61.3928 95.1871 60.4317C95.2481 59.4706 95.5421 58.5816 96.0693 57.7648C96.6088 56.9421 97.364 56.2992 98.3349 55.8361C99.3182 55.367 100.284 55.1968 101.231 55.3254C102.185 55.4356 103.042 55.7753 103.803 56.3446C104.57 56.8955 105.161 57.6065 105.577 58.4778C105.636 58.6023 105.689 58.7297 105.736 58.8601C105.79 58.9721 105.825 59.0622 105.842 59.1304L97.7582 62.9868C98.1108 63.5979 98.5552 64.0811 99.0914 64.4364C99.6341 64.7734 100.216 64.9693 100.838 65.024C101.455 65.0663 102.043 64.9538 102.603 64.6866C103.2 64.4016 103.689 63.9851 104.069 63.437C104.462 62.8829 104.642 62.3157 104.61 61.7353L106.216 61.4505C106.23 62.0244 106.114 62.599 105.869 63.1743C105.637 63.7436 105.284 64.2706 104.813 64.7554C104.354 65.2341 103.794 65.6309 103.135 65.9456ZM97.1589 61.8747L103.805 58.704C103.453 58.093 103.005 57.619 102.462 57.282C101.92 56.945 101.34 56.7554 100.724 56.7131C100.108 56.6708 99.5077 56.7892 98.9227 57.0683C98.3502 57.3414 97.8865 57.7307 97.5317 58.2361C97.1769 58.7415 96.9597 59.3111 96.8801 59.945C96.8129 60.5729 96.9058 61.2161 97.1589 61.8747Z"
                    fill="#EEF233"
                />
                <path
                    d="M118.599 60.7037C117.743 60.9171 116.927 60.893 116.151 60.6315C115.375 60.37 114.712 59.9597 114.16 59.4007L115.741 65.7435L113.974 66.1836L110.299 51.4306L111.864 51.0405L112.354 53.0076C112.609 52.2619 113.023 51.6117 113.595 51.057C114.164 50.4888 114.843 50.1064 115.633 49.9097C116.356 49.7297 117.055 49.7118 117.73 49.8562C118.406 50.0006 119.028 50.272 119.596 50.6705C120.164 51.0689 120.65 51.566 121.055 52.1617C121.469 52.7407 121.764 53.3848 121.941 54.094C122.188 55.0842 122.214 56.0441 122.019 56.9738C121.838 57.9001 121.454 58.6992 120.868 59.371C120.279 60.0294 119.523 60.4737 118.599 60.7037ZM117.677 59.3133C118.199 59.1832 118.641 58.9595 119.002 58.642C119.374 58.3079 119.662 57.9235 119.866 57.4888C120.081 57.0375 120.21 56.5576 120.254 56.0492C120.298 55.5409 120.258 55.0392 120.135 54.544C120.004 54.0222 119.787 53.5504 119.483 53.1287C119.193 52.7037 118.835 52.3522 118.411 52.0743C117.999 51.793 117.548 51.607 117.057 51.5163C116.575 51.4089 116.08 51.4185 115.571 51.5452C115.25 51.6252 114.931 51.7688 114.613 51.9759C114.308 52.1797 114.03 52.4337 113.779 52.7378C113.525 53.0286 113.326 53.3412 113.182 53.6755C113.038 54.0099 112.972 54.346 112.985 54.6838L113.73 57.6745C114.044 58.0795 114.415 58.4276 114.843 58.719C115.271 59.0103 115.734 59.2147 116.232 59.3322C116.727 59.4363 117.209 59.4299 117.677 59.3133Z"
                    fill="#EEF233"
                />
                <path
                    d="M132.701 58.9513C131.888 58.9865 131.145 58.8737 130.471 58.613C129.796 58.3385 129.207 57.9568 128.703 57.4679C128.198 56.9652 127.8 56.382 127.509 55.7182C127.218 55.0544 127.056 54.3506 127.024 53.6066C126.991 52.8488 127.091 52.1267 127.324 51.4403C127.557 50.7538 127.903 50.1453 128.363 49.6147C128.823 49.0703 129.377 48.6391 130.026 48.3211C130.688 47.9888 131.418 47.8054 132.217 47.7708C133.03 47.7356 133.774 47.8553 134.448 48.1298C135.122 48.3905 135.711 48.7722 136.216 49.2748C136.734 49.7632 137.139 50.3392 137.43 51.003C137.721 51.6667 137.883 52.3775 137.915 53.1353C137.948 53.8793 137.847 54.5945 137.615 55.2809C137.382 55.9673 137.036 56.5828 136.577 57.1272C136.117 57.6578 135.556 58.0893 134.894 58.4216C134.245 58.7396 133.514 58.9161 132.701 58.9513ZM128.885 53.5467C128.917 54.2907 129.105 54.959 129.448 55.5515C129.805 56.1434 130.26 56.6068 130.813 56.9418C131.365 57.263 131.972 57.4093 132.633 57.3807C133.294 57.352 133.886 57.147 134.408 56.7655C134.943 56.3835 135.356 55.8825 135.647 55.2625C135.951 54.6282 136.087 53.9391 136.055 53.1951C136.022 52.4511 135.828 51.7832 135.471 51.1913C135.128 50.5988 134.673 50.1353 134.106 49.801C133.554 49.466 132.946 49.3128 132.285 49.3414C131.624 49.37 131.033 49.582 130.511 49.9772C129.99 50.3587 129.576 50.8597 129.272 51.4802C128.981 52.1001 128.852 52.789 128.885 53.5467Z"
                    fill="#EEF233"
                />
                <path
                    d="M143.318 59.0528L145.013 48.3888L146.811 48.6747L146.109 53.0874L151.666 53.9709L152.368 49.5582L154.165 49.8441L152.47 60.5081L150.672 60.2222L151.413 55.5644L145.856 54.6808L145.115 59.3387L143.318 59.0528Z"
                    fill="#EEF233"
                />
                <path
                    d="M169.698 61.5019L170.473 60.04L178.769 64.4414L177.994 65.9033L169.698 61.5019Z"
                    fill="#EEF233"
                />
                <path
                    d="M192.251 88.0309L194.108 86.2911L188.28 80.0723L196.159 72.689L197.404 74.0173L190.687 80.3115L194.11 83.9643L200.827 77.6701L202.072 78.9984L195.355 85.2926L196.373 86.3794L193.354 89.2082L192.251 88.0309Z"
                    fill="#EEF233"
                />
                <path
                    d="M200.548 95.9847C200.122 95.2919 199.855 94.5819 199.747 93.8547C199.658 93.132 199.701 92.4255 199.875 91.7352C200.068 91.0494 200.38 90.4119 200.811 89.8227C201.249 89.2453 201.791 88.7577 202.436 88.3599C203.305 87.8248 204.221 87.5363 205.183 87.4944C206.145 87.4526 207.06 87.6501 207.929 88.0871C208.804 88.5358 209.524 89.2181 210.088 90.134C210.66 91.0617 210.932 92.0034 210.905 92.9593C210.897 93.9196 210.651 94.8083 210.166 95.6251C209.7 96.4465 209.056 97.1103 208.234 97.6165C208.117 97.6888 207.996 97.7553 207.871 97.8159C207.765 97.8809 207.68 97.9257 207.614 97.9501L202.917 90.3234C202.347 90.7392 201.914 91.2326 201.618 91.8037C201.34 92.3793 201.208 92.9792 201.22 93.6035C201.244 94.2205 201.418 94.7933 201.744 95.3217C202.091 95.8853 202.557 96.3269 203.142 96.6466C203.735 96.9779 204.318 97.0965 204.892 97.0024L205.347 98.5688C204.777 98.644 204.194 98.5904 203.596 98.4081C203.005 98.2376 202.443 97.9437 201.911 97.5265C201.386 97.121 200.932 96.6071 200.548 95.9847ZM203.959 89.6089L207.82 95.8793C208.39 95.4636 208.814 94.9679 209.091 94.3923C209.368 93.8167 209.495 93.2204 209.471 92.6034C209.447 91.9863 209.266 91.4018 208.926 90.8499C208.593 90.3098 208.157 89.8903 207.616 89.5914C207.076 89.2926 206.486 89.1374 205.848 89.1258C205.216 89.1261 204.586 89.2871 203.959 89.6089Z"
                    fill="#EEF233"
                />
                <path
                    d="M208.867 114.58L219.4 112.204L221.371 120.941L210.838 123.317L210.437 121.542L219.396 119.52L218.226 114.335L209.267 116.356L208.867 114.58Z"
                    fill="#EEF233"
                />
                <path
                    d="M211.409 135.548C211.39 134.666 211.595 133.875 212.021 133.177C212.448 132.478 212.995 131.922 213.662 131.508L207.127 131.645L207.089 129.825L222.29 129.506L222.323 131.119L220.297 131.162C220.967 131.575 221.51 132.123 221.924 132.804C222.352 133.484 222.575 134.231 222.592 135.045C222.607 135.789 222.47 136.475 222.18 137.102C221.889 137.729 221.487 138.275 220.973 138.741C220.458 139.207 219.866 139.571 219.196 139.833C218.539 140.109 217.846 140.255 217.115 140.27C216.095 140.292 215.153 140.104 214.289 139.709C213.426 139.327 212.732 138.776 212.206 138.056C211.695 137.335 211.429 136.5 211.409 135.548ZM212.969 134.957C212.98 135.495 213.101 135.975 213.33 136.398C213.574 136.834 213.885 137.2 214.264 137.495C214.656 137.804 215.096 138.037 215.582 138.192C216.068 138.347 216.566 138.42 217.076 138.409C217.614 138.398 218.122 138.29 218.6 138.087C219.079 137.898 219.501 137.627 219.866 137.275C220.231 136.936 220.513 136.537 220.71 136.077C220.921 135.632 221.022 135.147 221.011 134.623C221.004 134.292 220.934 133.949 220.803 133.593C220.671 133.251 220.485 132.923 220.244 132.611C220.017 132.299 219.756 132.035 219.462 131.821C219.168 131.606 218.855 131.468 218.522 131.406L215.441 131.471C214.976 131.687 214.555 131.972 214.176 132.325C213.797 132.677 213.495 133.084 213.27 133.544C213.059 134.003 212.959 134.474 212.969 134.957Z"
                    fill="#EEF233"
                />
                <path
                    d="M209.996 149.687C210.142 148.887 210.416 148.187 210.82 147.587C211.237 146.99 211.739 146.5 212.328 146.117C212.93 145.736 213.587 145.477 214.298 145.34C215.01 145.203 215.732 145.201 216.465 145.334C217.211 145.47 217.893 145.727 218.511 146.106C219.129 146.485 219.646 146.957 220.062 147.524C220.491 148.092 220.788 148.728 220.955 149.431C221.132 150.15 221.15 150.903 221.006 151.69C220.861 152.491 220.58 153.19 220.163 153.787C219.759 154.386 219.256 154.876 218.654 155.257C218.064 155.654 217.412 155.921 216.701 156.058C215.989 156.195 215.26 156.196 214.514 156.06C213.781 155.926 213.106 155.67 212.488 155.292C211.87 154.913 211.346 154.439 210.917 153.871C210.502 153.304 210.205 152.662 210.027 151.943C209.861 151.239 209.851 150.488 209.996 149.687ZM216.111 147.162C215.379 147.029 214.686 147.064 214.032 147.268C213.376 147.485 212.823 147.826 212.374 148.291C211.938 148.758 211.661 149.317 211.543 149.969C211.424 150.62 211.493 151.242 211.75 151.835C212.004 152.442 212.401 152.956 212.941 153.377C213.493 153.813 214.135 154.098 214.867 154.232C215.6 154.365 216.294 154.323 216.951 154.106C217.604 153.903 218.157 153.562 218.608 153.083C219.057 152.618 219.341 152.06 219.46 151.409C219.578 150.758 219.502 150.134 219.232 149.538C218.976 148.945 218.579 148.431 218.041 147.997C217.501 147.576 216.858 147.298 216.111 147.162Z"
                    fill="#EEF233"
                />
                <path
                    d="M206.525 162.55L215.099 165.841L216.434 162.365L217.94 162.943L214.619 171.595L213.112 171.017L214.447 167.541L205.872 164.249L206.525 162.55Z"
                    fill="#EEF233"
                />
                <path
                    d="M202.219 172.062L211.389 177.723L210.433 179.272L203.34 174.893L207.277 184.423L206.408 185.831L197.22 180.158L198.176 178.609L205.129 182.902L201.306 173.54L202.219 172.062Z"
                    fill="#EEF233"
                />
                <path
                    d="M196.315 186.238C196.821 186.706 197.125 187.251 197.227 187.872C197.329 188.512 197.248 189.178 196.982 189.872C196.716 190.565 196.274 191.246 195.655 191.914C195.327 192.268 194.95 192.614 194.524 192.953C194.108 193.3 193.704 193.575 193.312 193.775L193.889 194.309C194.496 194.871 195.14 195.129 195.821 195.084C196.513 195.047 197.168 194.695 197.786 194.027C198.189 193.592 198.497 193.097 198.71 192.542C198.923 192.007 199.089 191.381 199.208 190.664L200.599 191.05C200.475 191.894 200.261 192.653 199.955 193.329C199.659 194.014 199.254 194.634 198.738 195.191C197.802 196.203 196.778 196.74 195.668 196.802C194.568 196.874 193.517 196.446 192.515 195.518L189.161 192.413C188.999 192.263 188.849 192.181 188.712 192.167C188.576 192.172 188.432 192.246 188.281 192.388L187.249 191.432C187.351 191.282 187.443 191.152 187.527 191.041C187.601 190.941 187.666 190.86 187.722 190.799C188.013 190.486 188.319 190.328 188.641 190.325C188.954 190.332 189.215 190.415 189.426 190.573L189.955 191.006C189.829 190.25 189.901 189.481 190.174 188.7C190.446 187.918 190.835 187.254 191.341 186.708C191.829 186.182 192.37 185.809 192.967 185.591C193.574 185.382 194.167 185.33 194.747 185.435C195.337 185.549 195.859 185.816 196.315 186.238ZM190.975 190.879C191.004 191.132 191.057 191.359 191.133 191.561C191.219 191.772 191.313 191.925 191.414 192.018L192.401 192.932C192.813 192.731 193.208 192.486 193.588 192.198C193.977 191.92 194.326 191.614 194.635 191.28C195.263 190.602 195.637 189.924 195.757 189.246C195.878 188.587 195.706 188.042 195.241 187.611C194.988 187.377 194.679 187.213 194.314 187.12C193.95 187.046 193.571 187.071 193.175 187.193C192.77 187.326 192.385 187.59 192.02 187.984C191.636 188.399 191.351 188.869 191.167 189.393C190.992 189.927 190.928 190.422 190.975 190.879Z"
                    fill="#EEF233"
                />
                <path
                    d="M184.009 194.065L184.972 195.385C184.682 195.596 184.479 195.856 184.362 196.163C184.241 196.49 184.222 196.896 184.304 197.383C184.394 197.881 184.62 198.483 184.983 199.192C185.343 199.919 185.868 200.791 186.558 201.807L188.298 204.404L182.365 208.731L176.002 200.007L177.473 198.934L182.885 206.355L186.044 204.051L185.09 202.673C184.265 201.448 183.642 200.383 183.221 199.479C182.8 198.574 182.562 197.791 182.508 197.131C182.453 196.471 182.559 195.891 182.825 195.389C183.079 194.896 183.474 194.455 184.009 194.065Z"
                    fill="#EEF233"
                />
                <path
                    d="M166.012 204.908C166.752 204.57 167.489 204.391 168.224 204.373C168.952 204.374 169.648 204.503 170.312 204.76C170.969 205.036 171.563 205.423 172.095 205.923C172.615 206.428 173.032 207.026 173.348 207.715C173.773 208.643 173.947 209.587 173.871 210.547C173.794 211.507 173.486 212.392 172.946 213.2C172.394 214.014 171.628 214.645 170.65 215.093C169.66 215.546 168.692 215.701 167.746 215.557C166.794 215.432 165.942 215.079 165.191 214.497C164.433 213.934 163.853 213.214 163.451 212.336C163.394 212.211 163.343 212.083 163.298 211.951C163.246 211.839 163.212 211.748 163.196 211.679L171.34 207.952C170.998 207.335 170.561 206.845 170.03 206.481C169.493 206.136 168.914 205.931 168.293 205.866C167.678 205.814 167.088 205.917 166.523 206.175C165.922 206.451 165.426 206.86 165.037 207.402C164.636 207.949 164.447 208.514 164.47 209.094L162.86 209.354C162.855 208.78 162.979 208.207 163.233 207.636C163.475 207.07 163.836 206.549 164.315 206.072C164.781 205.6 165.347 205.212 166.012 204.908ZM171.922 209.073L165.226 212.138C165.569 212.755 166.009 213.236 166.546 213.581C167.084 213.927 167.66 214.126 168.275 214.178C168.89 214.23 169.493 214.121 170.082 213.851C170.659 213.587 171.129 213.205 171.492 212.706C171.854 212.206 172.081 211.64 172.17 211.007C172.247 210.38 172.165 209.736 171.922 209.073Z"
                    fill="#EEF233"
                />
                <path
                    d="M150.467 209.904C151.326 209.705 152.142 209.742 152.914 210.015C153.685 210.289 154.342 210.71 154.885 211.278L153.405 204.91L155.178 204.498L158.619 219.308L157.048 219.673L156.589 217.699C156.322 218.44 155.898 219.084 155.317 219.629C154.739 220.188 154.054 220.56 153.262 220.744C152.536 220.913 151.837 220.919 151.164 220.764C150.491 220.609 149.873 220.328 149.311 219.92C148.749 219.513 148.271 219.008 147.876 218.406C147.471 217.821 147.186 217.172 147.021 216.46C146.79 215.466 146.779 214.506 146.988 213.579C147.184 212.656 147.581 211.863 148.177 211.201C148.777 210.552 149.54 210.12 150.467 209.904ZM151.367 211.309C150.843 211.431 150.398 211.648 150.031 211.959C149.654 212.288 149.36 212.667 149.149 213.099C148.927 213.547 148.791 214.024 148.739 214.532C148.687 215.04 148.719 215.542 148.834 216.039C148.956 216.563 149.165 217.038 149.463 217.464C149.746 217.894 150.098 218.251 150.519 218.536C150.925 218.823 151.374 219.017 151.864 219.115C152.343 219.23 152.839 219.228 153.349 219.11C153.671 219.035 153.993 218.896 154.314 218.694C154.622 218.495 154.904 218.246 155.16 217.946C155.419 217.659 155.623 217.35 155.772 217.018C155.922 216.686 155.992 216.351 155.985 216.013L155.287 213.01C154.98 212.6 154.614 212.246 154.191 211.948C153.768 211.65 153.308 211.439 152.812 211.313C152.318 211.201 151.837 211.2 151.367 211.309Z"
                    fill="#EEF233"
                />
                <path
                    d="M140.953 211.451L141.481 222.236L134.828 222.561L134.749 220.95L139.584 220.713L139.135 211.54L140.953 211.451Z"
                    fill="#EEF233"
                />
                <path
                    d="M128.788 211.386L127.768 222.136L125.956 221.964L126.976 211.214L128.788 211.386ZM127.576 224.154L127.361 226.419L125.549 226.247L125.764 223.982L127.576 224.154Z"
                    fill="#EEF233"
                />
                <path
                    d="M119.225 210.073L116.458 220.489L114.699 220.021L116.839 211.965L108.855 218.491L107.256 218.066L110.029 207.63L111.788 208.097L109.69 215.994L117.546 209.627L119.225 210.073ZM111.521 220.462C112.481 220.716 113.147 221.136 113.52 221.72C113.889 222.318 113.983 222.956 113.803 223.636L112.563 223.307C112.662 222.934 112.619 222.587 112.433 222.266C112.23 221.955 111.829 221.72 111.229 221.561C110.642 221.405 110.199 221.409 109.899 221.572C109.595 221.748 109.364 222.029 109.204 222.414L107.965 222.085C108.145 221.405 108.544 220.898 109.161 220.562C109.775 220.24 110.561 220.207 111.521 220.462Z"
                    fill="#EEF233"
                />
                <path
                    d="M104.599 205.393L99.7997 215.066L98.1691 214.256L100.155 210.254L95.1149 207.753L93.1289 211.756L91.4982 210.947L96.2977 201.274L97.9283 202.083L95.832 206.308L100.872 208.809L102.969 204.584L104.599 205.393Z"
                    fill="#EEF233"
                />
                <path
                    d="M91.1994 197.888L84.5787 206.392L83.1424 205.273L88.2635 198.695L78.3636 201.579L77.0578 200.562L83.6912 192.042L85.1275 193.16L80.108 199.608L89.8283 196.82L91.1994 197.888Z"
                    fill="#EEF233"
                />
                <path
                    d="M79.5654 187.86L71.3807 194.871L70.1965 193.489L76.5274 188.065L66.2511 188.915L65.1745 187.658L73.3749 180.633L74.5592 182.016L68.3539 187.332L78.435 186.54L79.5654 187.86ZM67.3294 192.05C67.9754 192.804 68.2863 193.527 68.2622 194.22C68.2276 194.921 67.9433 195.501 67.4091 195.958L66.5747 194.984C66.868 194.733 67.0289 194.423 67.0574 194.053C67.0665 193.682 66.8692 193.261 66.4654 192.79C66.0707 192.329 65.7035 192.081 65.364 192.045C65.014 192.018 64.6639 192.118 64.3139 192.345L63.4795 191.371C64.0136 190.914 64.6299 190.722 65.3284 190.795C66.0165 190.877 66.6835 191.296 67.3294 192.05Z"
                    fill="#EEF233"
                />
                <path
                    d="M64.3382 163.723C64.7392 164.691 64.9012 165.587 64.824 166.41C64.7289 167.225 64.4303 167.946 63.9282 168.572L62.7468 167.449C63.0596 167.051 63.2436 166.594 63.2988 166.079C63.3487 165.55 63.2471 164.981 62.9938 164.369C62.6931 163.643 62.2964 163.135 61.8038 162.847C61.3111 162.558 60.7972 162.525 60.2621 162.746C59.7779 162.947 59.4605 163.28 59.31 163.745C59.1413 164.203 59.1773 164.74 59.4178 165.357L59.8373 166.37L58.7288 166.829L58.2856 165.759C58.1748 165.491 58.0135 165.282 57.8016 165.131C57.5718 164.972 57.3184 164.883 57.0416 164.864C56.7595 164.831 56.4783 164.873 56.198 164.989C55.7521 165.174 55.4612 165.481 55.3256 165.91C55.1772 166.345 55.2349 166.88 55.4987 167.517C55.715 168.04 55.999 168.437 56.3506 168.71C56.6969 168.969 57.1187 169.123 57.6162 169.171L57.4584 170.736C56.7276 170.665 56.0726 170.399 55.4934 169.938C54.8961 169.469 54.4286 168.826 54.091 168.011C53.8219 167.361 53.6928 166.743 53.7036 166.156C53.6964 165.562 53.8323 165.043 54.1112 164.599C54.3848 164.143 54.8019 163.798 55.3625 163.566C55.834 163.371 56.321 163.304 56.8237 163.364C57.3084 163.417 57.7424 163.618 58.1256 163.967C58.056 163.294 58.1782 162.706 58.4923 162.203C58.7936 161.705 59.2436 161.332 59.8425 161.084C60.4541 160.831 61.045 160.78 61.6154 160.932C62.1858 161.084 62.7044 161.399 63.1711 161.878C63.6379 162.356 64.0269 162.971 64.3382 163.723Z"
                    fill="#EEF233"
                />
                <path
                    d="M58.204 156.026C57.5301 156.172 56.9097 156.109 56.3428 155.836C55.7595 155.554 55.2601 155.104 54.8446 154.489C54.4292 153.873 54.1252 153.121 53.9326 152.231C53.8304 151.759 53.7629 151.252 53.73 150.709C53.6836 150.169 53.6907 149.68 53.7515 149.244L52.9832 149.41C52.1745 149.585 51.5936 149.965 51.2403 150.549C50.8736 151.137 50.7866 151.875 50.9792 152.765C51.1046 153.344 51.3336 153.88 51.6659 154.373C51.9819 154.855 52.3995 155.35 52.9188 155.858L51.8054 156.776C51.1848 156.191 50.6856 155.58 50.3076 154.942C49.9162 154.307 49.6402 153.619 49.4798 152.878C49.188 151.53 49.3348 150.383 49.9204 149.438C50.4924 148.496 51.4457 147.88 52.78 147.592L57.248 146.624C57.4637 146.578 57.6167 146.502 57.707 146.398C57.7809 146.283 57.8027 146.123 57.7724 145.918L59.1472 145.62C59.2121 145.79 59.2659 145.94 59.3085 146.072C59.3483 146.191 59.3769 146.29 59.3944 146.371C59.4849 146.789 59.4389 147.131 59.2564 147.396C59.0711 147.648 58.8533 147.815 58.603 147.897L57.9446 148.082C58.6368 148.412 59.225 148.912 59.7089 149.584C60.1929 150.255 60.5136 150.955 60.6712 151.682C60.8229 152.383 60.8171 153.041 60.6537 153.654C60.4768 154.271 60.1793 154.787 59.7612 155.202C59.3296 155.62 58.8106 155.895 58.204 156.026ZM57.464 148.99C57.2402 148.869 57.0238 148.782 56.8147 148.729C56.5921 148.678 56.4134 148.667 56.2786 148.697L54.9645 148.981C54.8932 149.434 54.8668 149.898 54.8851 150.374C54.89 150.853 54.9405 151.314 55.0368 151.759C55.2323 152.662 55.5733 153.357 56.0598 153.845C56.5298 154.321 57.0749 154.493 57.6949 154.359C58.0318 154.286 58.3431 154.126 58.6286 153.881C58.8978 153.625 59.0955 153.3 59.2218 152.906C59.3452 152.499 59.35 152.032 59.2362 151.506C59.1166 150.954 58.8949 150.451 58.5713 149.999C58.2342 149.55 57.8651 149.214 57.464 148.99Z"
                    fill="#EEF233"
                />
                <path
                    d="M58.8521 135.683C58.8738 136.496 58.7488 137.245 58.4773 137.928C58.1917 138.598 57.7935 139.188 57.2829 139.698C56.7719 140.194 56.1823 140.589 55.5142 140.883C54.8456 141.163 54.1323 141.313 53.3741 141.333C52.3539 141.36 51.4173 141.158 50.5643 140.725C49.7113 140.293 49.0256 139.669 48.5073 138.856C47.9752 138.042 47.6947 137.091 47.6657 136.002C47.6374 134.94 47.854 134.01 48.3156 133.211C48.763 132.399 49.3814 131.79 50.1707 131.382L50.7763 133.146C50.2736 133.435 49.891 133.832 49.6285 134.335C49.3522 134.839 49.2222 135.395 49.2383 136.001C49.2559 136.663 49.4444 137.265 49.8037 137.807C50.1627 138.336 50.6497 138.75 51.2647 139.051C51.8659 139.352 52.5525 139.493 53.3245 139.472C54.0827 139.452 54.7678 139.275 55.3797 138.942C55.9774 138.595 56.4484 138.147 56.7925 137.6C57.1362 137.039 57.2993 136.428 57.2817 135.766C57.2703 135.339 57.1836 134.934 57.0217 134.552C56.8594 134.157 56.6504 133.817 56.3945 133.534C56.1245 133.238 55.8364 133.039 55.5302 132.937L56.0205 131.144C56.5502 131.351 57.0279 131.676 57.4536 132.12C57.8651 132.55 58.1963 133.072 58.4472 133.687C58.6977 134.287 58.8327 134.952 58.8521 135.683Z"
                    fill="#EEF233"
                />
                <path
                    d="M59.1987 126.055L48.4936 124.641L48.7319 122.837L59.437 124.25L59.1987 126.055ZM46.4839 124.376L44.228 124.078L44.4663 122.274L46.7221 122.571L46.4839 124.376Z"
                    fill="#EEF233"
                />
                <path
                    d="M62.5521 111.72C62.0327 113.421 61.1257 114.55 59.8312 115.107C58.5366 115.663 56.9529 115.655 55.08 115.084C53.8006 114.693 52.7579 114.259 51.9518 113.783C51.1325 113.302 50.5037 112.764 50.0654 112.169C49.6139 111.569 49.3047 110.905 49.1379 110.177C48.9578 109.444 48.8719 108.639 48.8801 107.762L48.8894 102.985L50.4232 103.345L50.4106 107.992C50.4265 108.789 50.5447 109.489 50.765 110.09C50.9853 110.69 51.3434 111.218 51.8391 111.672C52.3349 112.126 53.0238 112.538 53.9057 112.908C53.3113 112.352 52.9276 111.673 52.7545 110.87C52.5681 110.063 52.6139 109.205 52.8917 108.295C53.1897 107.319 53.6498 106.544 54.2721 105.969C54.8852 105.378 55.6109 105.008 56.4491 104.861C57.2781 104.696 58.1608 104.756 59.0973 105.042C60.0997 105.348 60.9164 105.813 61.5474 106.439C62.1784 107.064 62.5882 107.823 62.7767 108.717C62.9652 109.611 62.8903 110.612 62.5521 111.72ZM61.0485 111.261C61.3747 110.192 61.3164 109.274 60.8736 108.504C60.4217 107.717 59.6748 107.165 58.6328 106.847C57.6172 106.537 56.7043 106.575 55.894 106.962C55.0837 107.349 54.5135 108.083 54.1833 109.165C53.9901 109.798 53.9468 110.412 54.0536 111.007C54.1643 111.589 54.4306 112.11 54.8524 112.57C55.265 113.013 55.8406 113.348 56.5793 113.573C57.2387 113.775 57.8705 113.83 58.4746 113.741C59.0695 113.634 59.5952 113.376 60.0515 112.968C60.4947 112.555 60.827 111.986 61.0485 111.261Z"
                    fill="#EEF233"
                />
              </g>
<!--              <path-->
<!--                  d="M161.481 122.508C160.858 120.184 159.03 118.351 156.709 117.725C152.468 116.561 135.5 116.542 135.5 116.542C135.5 116.542 118.535 116.523 114.291 117.636C112.016 118.256 110.137 120.13 109.508 122.451C108.39 126.692 108.379 135.489 108.379 135.489C108.379 135.489 108.368 144.329 109.478 148.527C110.101 150.848 111.93 152.682 114.253 153.307C118.538 154.472 135.46 154.491 135.46 154.491C135.46 154.491 152.427 154.51 156.668 153.399C158.987 152.776 160.823 150.946 161.454 148.625C162.575 144.386 162.583 135.592 162.583 135.592C162.583 135.592 162.638 126.749 161.481 122.508ZM130.073 143.639L130.086 127.389L144.188 135.527L130.073 143.639Z"-->
<!--                  fill="#EEF233"-->
<!--              />-->
            </svg>
          </a>
        </router-link>


      </div>
    </div>
  </div>
</template>

<script>
const navList = [
  {
    label: "Аллерон в д<span style=\"letter-spacing: 0.08em\">ії</span>",
    to: "/#alleron-in-action",
    class: "main margin-1",
  },
  {
    label: "Прояви алерг<span style=\"letter-spacing: 0.08em\">ії</span>",
    to: "/#home-section3",
    class: "main margin-2",
  },
  {
    label: "Як виникає алергія?",
    to: "/allergy#how-does-an-allergy-occur",
    class: "sub margin-2",
  },
  {
    label: "Які чинники підвищують ризик алерг<span style=\"letter-spacing: 0.08em\">ії</span>?",
    to: "/allergy#what-factors",
    class: "sub margin-2",
  },
  {
    label: "Риніт та кропив’янка — найпоширеніші прояви алерг<span style=\"letter-spacing: 0.08em\">ії</span>",
    to: "/allergy#rhinitis-and-urticaria",
    class: "sub margin-3",
  },
  {
    label: "Цікаві факти",
    to: "/#interesting-facts",
    class: "main margin-1",
  },
  {
    label: "Поради",
    to: "/#advices",
    class: "main",
  },
];

export default {
  name: "top-menu",

  data() {
    return {
      scroll: 0,
      dropName: "Прояви алерг<span style=\"letter-spacing: 0.08em\">ії</span>",
      open: false,

      navList,
    };
  },
  methods: {
    updateVH() {
      const vh = window.innerHeight * 0.01;
      if (this.open) {
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }
    },
  },

  watch: {
    open() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  },

  mounted() {

    window.addEventListener("resize", this.updateVH);

    this.scroll = window.scrollY;
    window.addEventListener("scroll", (event) => {
      this.scroll = window.scrollY;
    });
  },
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.top-menu {
  // box-shadow: 0 0 0 1px red inset;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  padding: 0 20px;
  height: 50px;

  @include gt-md {
    height: 70px;
  }

  &.active {
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: "";

    opacity: 0;
    transition: all 0.5s ease;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(
            98.83deg,
            rgba(66, 155, 112, 0.4) -12.61%,
            rgba(68, 158, 70, 0.4) 7.03%,
            rgba(62, 155, 70, 0.4) 21.76%,
            rgba(58, 154, 70, 0.4) 27.66%,
            rgba(33, 135, 65, 0.4) 57.66%,
            rgba(13, 120, 61, 0.4) 85.6%
    );
    backdrop-filter: blur(20px);
  }

  .visible {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include gt-md {
      max-width: $contentWidthLg;
    }

    &__logo {
      max-width: 100px;
      width: 100%;
      margin-right: 20px;
      position: relative;
      z-index: 3;

      cursor: pointer !important;


      @media screen and (max-width: 768px) {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        max-width: 100%;
        justify-content: center;
        align-items: center;
      }

      svg {
        width: 100%;
        height: auto;
        display: block;
        @media screen and (max-width: 768px) {
          max-width: 86px;
        }

      }
    }

    &__wrapper {
      max-width: 1200px;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__btn-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      z-index: 2;

      &.active {
        z-index: 120;
      }
    }

    &__item {
      margin-bottom: 5px;
      padding: 10px 15px 5px;
      border: 1px solid transparent;
      border-bottom: none;

      @media screen and (max-width: 900px) {
        padding: 0 10px;
      }

      &.single {
        margin-left: 5px;
        margin-right: 5px;
        text-decoration: none;

        @include Raleway-500;
        font-size: 16px;
        color: #ffffff;

        &.active,
        &:hover {
          border-color: #eef233;
          color: #eef233;
          border-radius: 18px 18px 0 0;
        }

        @media screen and (max-width: 900px) {
          font-size: 14px;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &.drop {
        position: relative;

        &:hover {
          .drop-hide {
            opacity: 1;
            visibility: visible;
          }
        }

        .drop-show {
          @include Raleway-500;
          font-size: 16px;
          color: #ffffff;

          border: 1px solid transparent;
          border-bottom: none;

          &.active {
            margin: -10px -15px -5px;
            padding: 10px 15px 5px;
            border-color: #eef233;
            color: #eef233;
            border-radius: 18px 18px 0 0;
          }

          @media screen and (max-width: 900px) {
            font-size: 14px;
          }
        }

        .drop-hide {
          position: absolute;
          left: 0;
          top: 0;

          opacity: 0;
          visibility: hidden;

          padding: 10px 15px;

          width: 100%;
          min-width: 212px;
          max-width: 212px;

          background: rgba(255, 255, 255, 1);

          border-radius: 18px;
          transition: all 0.2s ease;

          @media screen and (max-width: 900px) {
            min-width: 180px;
            max-width: 180px;
          }

          &__item {
            display: block;
            text-decoration: none;
            color: #0d783d;
            @include Raleway-400;

            font-size: 16px;
            margin-bottom: 20px;

            &.active,
            &:hover {
              font-weight: 600 !important;
            }

            @media screen and (max-width: 900px) {
              font-size: 14px;
            }

            &.router-link-exact-active {
              @include Raleway-500;
            }
          }
        }

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &.background {
        margin-top: 5px;
        padding: 9px 12px 9px 15px;

        background: #eef233;
        border-radius: 44px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        color: #0d783d;
        z-index: 20;
        position: relative;

        @include Raleway-600;

        font-size: 16px;

        text-decoration: none;
        margin-left: 33px;
        transition: all 0.2s ease;

        @media screen and (max-width: 900px) {
          font-size: 14px;
          margin-left: 15px;
        }

        @media screen and (min-width: 1023px) {
          &:hover {
            background: #ffffff;
          }
        }

        &:active {
          background: #ffffff;
        }

        @media screen and (max-width: 768px) {
          padding: 7.2px 12px;
          font-size: 11px;
        }

        .icon {
          margin-left: 10px;

          @media screen and (max-width: 768px) {
            display: none;
          }

          &.arrow-right {
            width: 18px;
            height: 18px;
            background: url("@/assets/icon/arrow-right.svg") center no-repeat;
          }
        }
      }
    }

    &__menu-mobile {
      display: none;

      @media screen and (max-width: 768px) {
        display: flex;
      }

      max-width: 28px;
      width: 100%;
      position: relative;
      z-index: 2;

      svg {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .hide {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 120;

    background: #ffffff;

    max-height: calc(100vh);
    max-height: calc((var(--vh, 1vh) * 100));
    display: none;

    &__item {
      margin-bottom: 5px;
      padding: 10px 15px 5px;
      border: 1px solid transparent;
      border-bottom: none;

      @media screen and (max-width: 900px) {
        padding: 0 10px;
      }

      position: absolute !important;
      right: 20px;
      top: 6px;


      &.background {
        margin-top: 5px;
        padding: 9px 12px 9px 15px;

        background: #eef233;
        border-radius: 44px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        color: #0d783d;
        z-index: 20;
        position: relative;

        @include Raleway-600;

        font-size: 16px;

        text-decoration: none;
        margin-left: 33px;
        transition: all 0.2s ease;

        @media screen and (max-width: 900px) {
          font-size: 14px;
          margin-left: 15px;
        }


        @media screen and (min-width: 1023px) {
          &:hover {
            background: #ffffff;
          }
        }

        &:active {
          background: #ffffff;
        }

        @media screen and (max-width: 768px) {
          padding: 7.2px 12px;
          font-size: 11px;
        }

        .icon {
          margin-left: 10px;

          @media screen and (max-width: 768px) {
            display: none;
          }

          &.arrow-right {
            width: 18px;
            height: 18px;
            background: url("@/assets/icon/arrow-right.svg") center no-repeat;
          }
        }
      }
    }

    @media screen and(max-width: 768px) {
      display: flex;
    }

    &__close {
      position: absolute;
      left: 18px;
      top: 14px;
      max-width: 28px;
      width: 100%;
      z-index: 20;

      svg {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__list-wrapper {
      padding: 30vh 28px 0 48px;

      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      @media screen and (max-width: 425px) {
        padding: 14vh 28px 0 48px;
      }
    }

    &__list-item {
      text-decoration: none;
      position: relative;

      &.router-link-exact-active {
        font-weight: 600 !important;
      }

      &:active {
        font-weight: 600;
      }

      &.active {
        font-weight: 600 !important;
      }

      &.margin-1 {
        margin-bottom: 45px;

        @media screen and (max-height: 670px) {
          margin-bottom: 35px;
        }
        @media screen and (max-height: 580px) {
          margin-bottom: 30px;
        }
      }

      &.margin-2 {
        margin-bottom: 35px;
        @media screen and (max-height: 670px) {
          margin-bottom: 25px;
        }
        @media screen and (max-height: 580px) {
          margin-bottom: 20px;
        }
      }

      &.margin-3 {
        margin-bottom: 54px;
        @media screen and (max-height: 670px) {
          margin-bottom: 44px;
        }
        @media screen and (max-height: 580px) {
          margin-bottom: 39px;
        }
      }

      &.main {
        color: #0d783d;
        @include Raleway-500;
        font-size: 19px;

        @media screen and (min-width: 390px) {
          font-size: 21px;
        }

        &:active {
          font-weight: 600;
        }
      }

      &.sub {
        color: #0d783d;
        font-size: 16px;
        padding-left: 30px;

        @include Raleway-400;

        &:active {
          font-weight: 600;
        }

        &.active {
          font-weight: 600;
        }
      }
    }

    &__round-animation {
      position: absolute;
      right: -50px;
      bottom: -220px;

      .animation {
        transform-origin: center;
        animation: 10s rotate linear infinite;
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
