<template>
  <div class="full-scroll">
    <div class="fs-wrap">
      <slot></slot>
    </div>

    <div v-if="!isOff" class="full-scroll__menu">
      <router-link
          v-for="(offset, index) in sections"
          v-bind:key="index"
          :to="{ name: $route.name, hash: `#${offset.id}`, replace: true }"
          class="menu-point"
          :class="{ active: activeSection == index }"
      ></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "full-scroll",

  props: {
    selector: {type: String, default: "section"},
    breakpoint: {type: Number, default: 320},
  },

  data() {
    return {
      inMove: false,
      activeSection: 0,
      offsets: [],
      touchStartY: 0,

      sections: [],
      isOff: true,
      isInit: false,
    };
  },

  methods: {
    calculateSectionOffsets() {
      const items = document.querySelectorAll(this.selector);
      const length = items.length;

      for (let i = 0; i < length; i++) {
        const sectionOffset = items[i].offsetTop;
        this.offsets.push(sectionOffset);
      }

      this.sections = this.offsets.map((offset, i) => ({
        offset,
        id: items[i].id,
      }));
    },

    scrollToSection(id, force = false, replace = true) {
      if (this.inMove && !force) {
        // || this.isOff
        return false;
      }

      this.activeSection = id;
      this.inMove = true;
      const item = document.querySelectorAll(this.selector)[id];

      if (replace) {
        this.$router.replace(`${this.$route.path}#${item.id}`);
      }

      this.$nextTick(() => {
        item.scrollIntoView({behavior: "smooth"});
        setTimeout(() => (this.inMove = false), 400);
      });

      return true;
    },

    handleKeys(e) {
      const disable = [
        "PageUp",
        "PageDown",
        "Numpad1",
        "Numpad2",
        "Numpad3",
        "Numpad4",
        "Numpad5",
        "Numpad6",
        "Numpad7",
        "Numpad8",
        "Numpad9",
      ];
      const up = ["ArrowUp"];
      const down = ["Space", "ArrowDown"];

      if (disable.includes(e.code)) {
        e.preventDefault();
      } else if (down.includes(e.code)) {
        e.preventDefault();
        if (this.inMove) return;
        this.scrollToNext();
      } else if (up.includes(e.code)) {
        e.preventDefault();
        if (this.inMove) return;
        this.scrollToPrev();
      }
    },

    handleInnerScroll(e) {
      const item = (e.path || (e.composedPath && e.composedPath()) || []).find(
          ({dataset}) => (dataset && dataset.scroll) !== void 0
      );

      if (item) {
        const scroll = {
          top: item.scrollTop,
          bottom: item.scrollHeight - item.offsetHeight - item.scrollTop,
        };

        if (e.deltaY > 0 && scroll.bottom <= 0) {
          return false;
        } else if (e.deltaY < 0 && scroll.top <= 0) {
          return false;
        }

        return true;
      }

      return false;
    },

    handleMouseWheelDOM: function (e) {
      if (this.handleInnerScroll(e)) return;

      e.preventDefault();
      if (this.inMove) return;


      if (e.deltaY > 40) {
        this.scrollToNext();
      } else if (e.deltaY < -40) {
        this.scrollToPrev();
      }
    },

    handleMouseWheel: function (e) {
      if (this.handleInnerScroll(e)) return;

      e.preventDefault();
      if (this.inMove) return;

      if (e.wheelDelta < 30) {
        this.scrollToNext();
        this.scrollToNext();
      } else if (e.wheelDelta > 30) {
        this.scrollToPrev();
      }

      return;
    },

    handleResize() {
      this.isOff = window.innerWidth <= this.breakpoint;

      if (this.isOff && this.isInit) {
        this.remove();
      } else if (!this.isOff && !this.isInit) {
        this.init();
      }

      if (this.isInit && !this.isOff) {
        const item = document.querySelectorAll(this.selector)[
            this.activeSection
            ];
        window.scrollTo({top: item.offsetTop});
      }
    },

    scrollToPrev() {
      if (this.activeSection - 1 < 0) {
        // this.activeSection = this.offsets.length;
        return;
      }
      this.activeSection--;
      this.scrollToSection(this.activeSection, true);
    },

    scrollToNext() {
      if (this.activeSection + 1 >= this.offsets.length) {
        // this.activeSection = -1;
        return;
      }
      this.activeSection++;
      this.scrollToSection(this.activeSection, true);
    },

    touchStart(e) {
      e.preventDefault();
      this.touchStartY = e.touches[0].clientY;
    },

    touchMove(e) {
      if (this.inMove) return false;
      e.preventDefault();
      const currentY = e.touches[0].clientY;
      if (this.touchStartY < currentY) {
        this.scrollToNext();
      } else {
        this.scrollToPrev();
      }
      this.touchStartY = 0;
      return false;
    },

    init() {
      document.addEventListener("keydown", this.handleKeys, {passive: false});
      document.addEventListener("wheel", this.handleMouseWheelDOM, {
        passive: false,
      }); // Mozilla Firefox
      document.addEventListener("mousewheel", this.handleMouseWheel, {
        passive: false,
      }); // Other browsers
      document.addEventListener("touchstart", this.touchStart, {
        passive: false,
      }); // mobile devices
      document.addEventListener("touchmove", this.touchMove, {
        passive: false,
      }); // mobile devices

      this.isInit = true;
      this.handleResize();
    },

    remove() {
      document.removeEventListener("keydown", this.handleKeys, {});
      document.removeEventListener("mousewheel", this.handleMouseWheel, {
        passive: false,
      }); // Other browsers
      document.removeEventListener("wheel", this.handleMouseWheelDOM, {
        passive: false,
      }); // Mozilla Firefox
      document.removeEventListener("touchstart", this.touchStart); // mobile devices
      document.removeEventListener("touchmove", this.touchMove); // mobile devices

      this.isInit = false;
    },

    getIndexById(hash) {
      const index = this.sections.findIndex(({id}) => `#${id}` === hash);
      return index < 0 ? 0 : index;
    },
  },

  watch: {
    "$route.hash"(val) {
      this.$nextTick(() => {
        const {hash} = this.$route;
        this.scrollToSection(this.getIndexById(hash), true, false);
      });
    },
  },

  mounted() {
    this.calculateSectionOffsets();

    this.$nextTick(() => {
      const {hash} = this.$route;
      this.scrollToSection(this.getIndexById(hash), true, false);
    });

    this.init();
    window.addEventListener("resize", this.handleResize, {
      passive: false,
    });
  },

  destroyed() {
    this.remove();
    window.removeEventListener("resize", this.handleResize, {
      passive: false,
    });
  },
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.full-scroll__menu {
  z-index: 9999;
  position: fixed;
  top: calc(50% - 15vh / 2);
  right: 5px;
  transform: translateY(-50%);

  padding: 0 10px;
  border-radius: 14px;

  background: rgba(13, 120, 61, 0.4);
  backdrop-filter: blur(20px);

  @media screen and (min-width: 1260px) {
    right: calc((100vw - $contentWidthLg) / 2 - 15px);

  }

}

.full-scroll__menu .menu-point {
  cursor: pointer;
  width: 10px;
  height: 10px;
  background-color: #eef23300;
  display: block;
  margin: 11px 0;
  box-shadow: 0 0 0 1px #eef233;
  border-radius: 50%;
  transition: 0.4s ease all;
}

.full-scroll__menu .menu-point.active {
  background-color: #eef233;
}
</style>
