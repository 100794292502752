<template>
  <div id="info" class="bottom-footer">
    <div class="wrap">
      <div class="bottom-footer__link-wrapper">
        <p class="bottom-footer__title">Посилання:</p>

        <div class="bottom-footer__scroll-wrapper" data-scroll>
          <p class="bottom-footer__item">
            <span style="justify-content: flex-end">*</span>мається на увазі суттєве зменшення симптомів алерг<span style="letter-spacing: 0.08em">ії</span>.
          </p>
          <p class="bottom-footer__item">
            <span>1.</span>Інструкція для медичного застосування лікарського
            засобу АЛЕРОН, таблетки 5 мг. РП №UA/5615/01/01. Наказ МОЗ України
            №2032 від 04.09.2020.
          </p>
          <p class="bottom-footer__item">
            <span>2.</span>Gillman Sherwin, Michel Gillard, Margherita S.
            Benedetti. The concept of receptor occupancy to predict clinical
            efficacy: a comparison of second generation H1-anthistamines.
            Sovremennaya pediatriya. 2015: 5(69): 29-35.
          </p>
          <p class="bottom-footer__item">
            <span>3.</span>Щербак И.Б. Акценты антигистаминной терапии:
            левоцетиризин.
            https://umj.com/ua/article/35126/akcenty-antigistaminnoj-terapii-levocetirizin.
            Дата зверенення: 27.10.2021
          </p>
          <p class="bottom-footer__item">
            <span>4.</span>Bousquet, J., Anto, J.M., Bachert, C. et al. Allergic
            rhinitis. Nat Rev Dis Primers 6, 95 (2020).
            https://doi.org/10.1038/s41572-020-00227-0
          </p>
          <p class="bottom-footer__item">
            <span>5.</span>Philippe Devillier, Nicolas Roche and Christophe
            Faisy. Clinical Pharmacokinetics and Pharmacodynamics of
            Desloratadine, Fexofenadine and Levocetirizine. Clin Pharmacokinet
            2008; 47 (4): 217-230.
          </p>
          <p class="bottom-footer__item">
            <span>6.</span>Grant J. et al. A doubleblind,single-dose, crossover
            comparison of levocetirizine with ebastine, fexofenadine,
            loratadine, mizolastine, and placebo: suppression of
            histamine-induced wheal and-flare response during 24 hours in
            healthy male subjects Ann Allergy Asthma Immunol. 2002.
            Feb;88(2):190-7.
          </p>
          <p class="bottom-footer__item">
            <span>7.</span>Small P. et al, Allergic rhinitis. Allergy, Asthma &
            Clinical Immunology volume 14, Article number: 51 (2018).
          </p>
          <p class="bottom-footer__item">
            <span>8.</span>Dykewicz M. Treatment of seasonal allergic rhinitis.
            Ann Allergy Asthma Immunol. 2017:1e23.
          </p>
          <p class="bottom-footer__item">
            <span>9.</span>Nettis et al. Urticaria: recommendations from the
            Italian Society of Allergology, Asthma and Clinical Immunology and
            the Italian Society of Allergological, Occupational and
            Environmental Dermatology. Clin. Mol. Allergy. 2020;18:8.
          </p>
          <p class="bottom-footer__item">
            <span>10.</span>Scordamaglia F. Levocetirizine in the treatment of
            allergic diseases. Expert Opin. Pharmacother. 2009;10(14):2367-2377.
          </p>
          <p class="bottom-footer__item">
            <span>11.</span>Potter P et al. Comparison of the efficacy of
            levocetirizine 5mg and desloratadine 5mg in chronic idiopathic
            urticaria patients. Allergy. 2009 Apr;64(4):596-604. doi:
            10.1111/j.1398-9995.2008.01893.x.
          </p>
          <p class="bottom-footer__item">
            <span>12.</span>American College of Allergy, Asthma & Immunology.
            Food Allergy. https://acaai.org/allergies/allergic-conditions/food/
            (дата звернення: 02.11.21).
          </p>
          <p class="bottom-footer__item">
            <span>13.</span>Asthma and Allergy Foundation of America. Allergy
            Facts and Figures. https://www.aafa.org/allergy-facts/ (дата
            звернення: 02.11.21).
          </p>
          <p class="bottom-footer__item">
            <span>14.</span>Scadding G.K et al. BSACI guideline for the
            diagnosis and management of allergic and non-allergic rhinitis. Clin
            Exp Allergy. 2017;47:856–889.
          </p>
        </div>

        <div class="bottom-footer__top-line"></div>

        <div class="bottom-footer__line">
          <div class="bottom-footer__text-wrapper">
            <div class="bottom-footer__green-text">
              Реклама лікарського засобу АЛЕРОН таблетки, вкриті оболонкою (РП:
              UA/5615/01/01, наказ МОЗ №2032 від 04.09.2020). Перед
              застосуванням обов’язково ознайомтеся з інструкцією для медичного
              застосування та проконсультуйтеся з лікарем. Протипоказання та
              можливі побічні ефекти наведені в інструкц<span style="letter-spacing: 0.08em">ії</span> для медичного
              застосування лікарського засобу. Продається в аптеках без рецепта.
              Зберігати в недоступному для дітей місці. Представник виробника:
              ТОВ «Тева Україна», 01042, м. Київ, просп. П. Тичини, 1-В, поверх
              9, +380 44 394-70-80; www.teva.ua.
            </div>
            <div class="bottom-footer__green-text">
              ALRN-UA-00083. Термін д<span style="letter-spacing: 0.08em">ії</span> матеріалу: 01.10.2025
            </div>
          </div>

<!--          <div class="bottom-footer__icon">-->
<!--            <img-->
<!--                src="@/assets/icon/youtube.svg"-->
<!--                alt=""-->
<!--                style="width: 40px; height: 40px"-->
<!--            />-->
<!--          </div>-->
        </div>
      </div>

      <div class="bottom-footer__logo-wrapper">
        <div class="bottom-footer__left-logo">
          <img src="@/assets/logo_aleron.svg" alt="Aleron logo"/>
        </div>

        <div class="bottom-footer__right-logo">
          <img src="@/assets/logo_teva.svg" alt="Teva logo"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottom-footer",

  methods: {
    handleScroll(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.wrap {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: $contentWidthSm;

  @include gt-md {
    max-width: $contentWidthLg;
  }
}

.bottom-footer {
  margin: 0 auto;
  padding: 70px 20px calc(15vh + 30px);
  width: 100%;

  display: flex;
  align-items: center;



  @include gt-md {
    // max-width: $contentWidthLg;
    // padding-top: $headerSize + 5px;
    // padding-bottom: calc(15vh + 30px);
  }

  &__link-wrapper {
    padding: 25px 20px;
    border: 1px solid #eef233;

    @include gt-md {
      padding: 25px 56px;
      // height: calc(100% - 95px);
      height: calc(100% - 95px);
    }
  }

  &__title {
    @include Ubuntu-700;
    font-size: 18px;
    line-height: 24px;
    color: #eef233;
      cursor: default;

    margin-bottom: 15px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
      margin: 0;

    }
  }

  &__scroll-wrapper {
    max-height: 247px;
    overflow-y: auto;

    position: relative;
    z-index: 1;
    padding-right: 20px;

    @include gt-md {
      max-height: calc(100% - 95px - 40px - 50px);
      @media screen and (max-height: 775px) {
        max-height: calc(100% - 95px - 40px - 50px);
      }
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;

      height: 50px;
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 5px;
      cursor: pointer !important;

    }

    &::-webkit-scrollbar-track {
      overflow: hidden;
      border: 5px solid rgba(255, 255, 255, 0.3);
      cursor: pointer !important;

    }

    &::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      cursor: pointer !important;

    }
  }

  &__item {
    cursor: default;
    margin-bottom: 10px;
    display: flex;

    @include Raleway-300;
    font-size: 14px;
    line-height: 16px;
    word-break: break-all;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #ffffff;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }

    span {
      display: flex;
      min-width: 17px;
      margin-right: 5px;

      justify-content: flex-start;
    }
  }

  &__top-line {
    margin-top: 20px;

    width: 100%;
    height: 1px;
    background: #eef233;
  }

  &__line {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
  }

  &__text-wrapper {
    margin-right: 13px;
  }

  &__green-text {
    color: #eef233;
    font-size: 13px;
    line-height: 15px;
    font-feature-settings: "pnum" on, "lnum" on;
    text-align: justify;
    cursor: default;

    @include Raleway-300;

    @media screen and (min-width: 390px) {
      font-size: 15px;
    }

    @media screen and (max-width: 768px) {
      font-size: 8px;
      line-height: 9px;
    }

    &:last-child {
      margin-top: 18px;

      @media screen and (max-width: 768px) {
        margin-top: 0;
      }
    }
  }

  &__icon {
    max-width: 40px;
    width: 100%;
    margin-left: 13px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      display: none;
    }

    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__logo-wrapper {
    display: flex;
    justify-content: space-between;

    margin-top: 30px;
  }

  &__left-logo {
    max-width: 204px;
    width: 100%;

    @media screen and (max-width: 768px) {
      max-width: 157px;
    }

    img {
      width: 100%;
      height: auto;

      display: block;
    }
  }

  &__right-logo {
    max-width: 150px;
    width: 100%;

    @media screen and (max-width: 768px) {
      max-width: 101px;
    }

    img {
      width: 100%;
      height: auto;

      display: block;
    }
  }
}
</style>
