<template>
  <full-scroll selector=".fullscreen" :breakpoint="1024" class="wrap">
    <section1 class="fullscreen section"></section1>
    <section2 class="fullscreen section"></section2>
    <section3 class="fullscreen section"></section3>
    <section4 class="fullscreen section"></section4>
  </full-scroll>
</template>

<script>
import FullScroll from "@/components/full-scroll";
import section1 from "@/components/allergy/section-1";
import section2 from "@/components/allergy/section-2";
import section3 from "@/components/allergy/section-3";
import section4 from "@/components/allergy/section-4";

export default {
  components: {
    FullScroll,
    section1,
    section2,
    section3,
    section4,
  },
  data() {
    const vue = this;

    return {

      trigger25: true,
      trigger50: true,
      trigger75: true,
      trigger90: true,
    }
  },

  mounted() {
    document.addEventListener('scroll', () => {

      var h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

      var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);

      if (percent >= 25 && percent < 50 && this.trigger25) {
        this.trigger25 = false
        gtag('event', 'scroll', {'event_category': 'moreThan25%', 'event_label': "сторінка алергії"})
      } else if (percent >= 50 && percent < 75 && this.trigger50) {
        this.trigger50 = false
        gtag('event', 'scroll', {'event_category': 'moreThan50%', 'event_label': "сторінка алергії"})
      } else if (percent >= 75 && percent < 90 && this.trigger75) {
        this.trigger75 = false
        gtag('event', 'scroll', {'event_category': 'moreThan75%', 'event_label': "сторінка алергії"})
      } else if (percent >= 90 && this.trigger90) {
        this.trigger90 = false
        gtag('event', 'scroll', {'event_category': 'moreThan90%', 'event_label': "сторінка алергії"})
      }

      return false
    });
  }
};
</script>

<style scoped lang="scss">
@import "../css/vars";

.wrap {
  overflow: hidden;
}
</style>
