<template>
  <section id="interesting-facts" class="section">
    <div class="section__container">

      <div class="section__wrapper">


        <div class="section__content--title">
          Цікаві факти про алергію
        </div>
        <div class="section__content--circle"></div>
        <swiper ref="mySwiper" :options="swiperOption">
          <swiper-slide
              v-for="(item, i) in list"
              :key="i"
              class="section__content--card"
              :class="`card-${i + 1}`">

            <div v-if="i === 4" class="section__content--circle-mob"></div>

            <img class="section__content--card-img" :src="item.image"/>
            <div
                class="section__content--card-text"
                v-html="item.text"
            >
            </div>
          </swiper-slide>
          <div class="swiper-button-prev slider-btn" slot="button-prev">
            <img src="../../assets/arrow-left.png" alt="">
          </div>
          <div class="swiper-button-next slider-btn" slot="button-next">
            <img src="../../assets/arrow-right.png" alt="">
          </div>
        </swiper>
      </div>
    </div>

  </section>
</template>

<script>


export default {
  data() {
    return {
      // drag: false,
      // pos: null, //{ top: 0, left: 0, x: 0, y: 0 },

      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },

      list: [
        {
          image: require("@/assets/home_icon/comp1.jpg"),
          text: "Близько 90% усіх харчових алергій припадає на один із 8 видів продуктів: яйця, молоко, арахіс, горіхи, риба, молюски, пшениця та соя<sup>12</sup>",
        },
        {
          image: require("@/assets/home_icon/comp2.jpg"),
          text: "Один тест на алергію передбачає введення алергену безпосередньо під шкіру. Якщо реакція відбудеться, вона буде обмежена невеликою площею<sup>12</sup>",
        },
        {
          image: require("@/assets/home_icon/comp3.jpg"),
          text: "Пеніцилін є найпоширенішим тригером алерг<span style=\"letter-spacing: 0.08em\">ії</span> серед лікарських засобів<sup>13</sup>",
        },
        {
          image: require("@/assets/home_icon/comp4.jpg"),
          text: "Близько 9,7% медичних працівників мають алергію на латекс<sup>13</sup>",
        },
        {
          image: require("@/assets/home_icon/comp5.jpg"),
          text: "Найчастіше причинами анафілакс<span style=\"letter-spacing: 0.08em\">ії</span> (небезпечної для життя реакц<span style=\"letter-spacing: 0.08em\">ії</span>) є ліки, їжа та укуси комах<sup>13</sup>",
        },
        {
          image: require("@/assets/home_icon/comp6.jpg"),
          text: "Системні алергічні реакц<span style=\"letter-spacing: 0.08em\">ії</span> на укуси комах трапляються протягом життя у 5% людей. У бджолярів їх поширеність сягає 32%<sup>13</sup>",
        },
      ],
    };
  },

  methods: {
    // handleMouseDown(e) {
    //   const el = this.$refs.content;
    //
    //   this.drag = true;
    //   this.pos = {
    //     left: el.scrollLeft,
    //     top: el.scrollTop,
    //     x: e.clientX,
    //     // y: e.clientY,
    //   };
    // },

    // slide(e){
    //
    // },

    // handleMouseUp(e) {
    //   this.drag = false;
    // },

    // handleMouseMove(e) {
    //   e.preventDefault();
    //   if (!this.drag) return;
    //
    //   const el = this.$refs.content;
    //   const dx = e.clientX - this.pos.x;
    //   const dy = e.clientY - this.pos.y;
    //
    //   // el.scrollTop = this.pos.top - dy;
    //   el.scrollLeft = this.pos.left - dx;
    // },
  },
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

$paddingXSm: calc((100vw - $contentWidthSm) / 2);
$paddingXLg: calc((100vw - $contentWidthLg) / 2);

$spacingSm: max(20px, $paddingXSm);
$spacingLg: max(20px, $paddingXLg);

$cardWSm: 250px;
$cardPSm: 220px;
$cardPSmF: 160px;

$cardW: 410px;
$cardWMob: 280px;
$cardP: 70px;

$listSizeSm: calc($cardWSm * 3 + $cardPSmF + $cardPSm * 2 + 40px);
$listSizeLg: calc($cardW * 6 + $cardP * 5 + 40px);
$listSizeLgMob: calc($cardWMob * 6 + $cardP * 5 + 40px);

$containerPSm: max(40px, calc(100% - $contentWidthSm));
$containerPLg: max(40px, calc(100% - $contentWidthLg));

.custom-scroll {
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-track {
    padding: 5px;
    overflow: hidden;
    border: 5px solid rgba(255, 255, 255, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    padding: 5px;
    background-color: #ffffff;
  }
}

.section {
  overflow: visible;
  padding-left: 20px;
  padding-right: 20px;


  @include gt-md {
    height: 100vh;
    padding-bottom: calc((var(--vh, 1vh) * 15) + 40px);
    @media screen and (max-height: 675px) {
      padding-bottom: calc((var(--vh, 1vh) * 15) + 15px);
    }
  }

  &__container {
    padding: 0 20px;


    max-width: calc(1220px + 40px);
    display: flex;
    align-items: center;

    position: relative;

    @media screen and (max-width: 1024px) {
      max-width: 358px;
      min-width: 1px;
      width: 100%;
      padding: 0 0;
      height: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 40px;
      left: 50%;
      width: 300px;
      height: 300px;
      background-color: #eef233;
      opacity: 1;
      filter: blur(120px);
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &__wrapper {
    padding: 0 53px;
    border: 1px solid #EEF233;


    max-width: 1220px;
    display: flex;
    height: 100%;
    align-items: center;

    position: relative;

    @media screen and (max-width: 1024px) {
      max-width: calc(358px);
      padding: 0 20px;
      width: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 40px;
      left: 50%;
      width: 300px;
      height: 300px;
      background-color: #eef233;
      opacity: 1;
      filter: blur(120px);
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &__content {
    // max-height: 600px;list-item item-4
    height: 100%;


    &--title {
      margin-bottom: 20px;
      padding-left: 53px;

      @include Ubuntu-700;
      font-size: 19px;
      line-height: 120%;

      @media screen and (min-width: 390px) {
        font-size: 21px;
      }

      position: absolute;
      z-index: 11;
      top: 50px;
      left: 0;


      @media screen and (max-width: 1260px) {
        left: 20px;
      }

      @media screen and (max-width: 1024px) {
        padding-left: 0;
      }

      @include gt-md {
        margin-bottom: 25px;
        font-size: 32px;

        @media screen and (max-height: 775px) {
          top: 20px;
        }
      }
    }

    &--circle {
      position: absolute;
      z-index: 11;
      top: calc(26%);
      right: 50px;
      width: 93px;
      height: 93px;

      border-radius: 50%;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("@/assets/circle.svg");
      animation: rotate 10s infinite linear;

      display: none;


      @include gt-md {
        display: block;
        top: -246px;
        bottom: 0;
        margin: auto 0;
        left: 70.5%;
        width: 165px;
        height: 165px;

        @media screen and (max-height: 810px) {
          width: 120px;
          height: 120px;
          top: -270px;
          left: 72.5%;

        }
      }
    }

    &--circle-mob {
      position: absolute;
      z-index: 11;
      top: -40px;
      right: -40px;
      width: 93px;
      height: 93px;

      border-radius: 50%;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("@/assets/circle.svg");
      animation: rotate 10s infinite linear;
      display: block;

      @include gt-md {
        display: none;
      }
    }

    &--card-img {
      max-width: 410px;
      width: 100%;
      height: auto;
      cursor: grab;

      display: block;


    }

    &--card-text {
      max-width: 410px;
      width: 100%;
      height: auto;

      display: block;
      margin-top: 15px;
      font-size: 18px;
      cursor: grab;

      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }


    }
  }

  .swiper-container {
    cursor: grab;

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      min-height: 560px;
      height: 100%;
    }
  }

  .swiper-button-prev {
    cursor: pointer !important;
    top: inherit;
    bottom: 30px;
    left: -75px;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    width: 35px;
    height: 24px;

    img {
      width: 100%;
      height: auto;
      cursor: pointer !important;

      display: block;
    }

    &:after {
      display: none;
    }
  }

  .swiper-button-next {
    top: inherit;
    cursor: pointer !important;
    bottom: 30px;
    left: 75px;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    width: 35px;
    height: 24px;

    img {
      width: 100%;
      height: auto;
      cursor: pointer !important;

      display: block;
    }

    &:after {
      display: none;
    }
  }

  .swiper-slide {
    width: 41%;
    cursor: grab;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 1024px) {
      width: 78%;
    }

    &.card-1 {
      margin-top: 50px;
      @media screen and (max-width: 1024px) {
        margin-top: -50px;
      }
      @media screen and (max-width: 360px) {
        margin-top: -30px;

      }
      @include gt-md {

        @media screen and (max-height: 810px) {
          margin-top: 20px;
        }
      }
    }

    &.card-2 {
      @media screen and (max-width: 1024px) {
        margin-top: 20px;
      }
      @media screen and (max-width: 360px) {
        margin-top: 0px;

      }
      @include gt-md {

        @media screen and (max-height: 810px) {
          margin-top: 0px;
        }
      }
    }

    &.card-3 {

      margin-top: 100px;

      @media screen and (max-width: 1024px) {
        margin-top: -40px;
      }
      @media screen and (max-width: 360px) {
        margin-top: -20px;

      }
      @include gt-md {

        @media screen and (max-height: 810px) {
          margin-top: 30px;
        }
      }
    }

    &.card-4 {

      margin-top: 30px;
      @media screen and (max-width: 1024px) {
        margin-top: 30px;
      }
      @media screen and (max-width: 360px) {
        margin-top: 10px;

      }
      @include gt-md {

        @media screen and (max-height: 810px) {
          margin-top: 10px;
        }
      }
    }

    &.card-5 {
      margin-top: -30px;
      @media screen and (max-width: 1024px) {
        margin-top: -30px;
      }
      @media screen and (max-width: 360px) {
        margin-top: -10px;

      }
      @include gt-md {

        @media screen and (max-height: 810px) {
          margin-top: -10px;
        }

      }
    }

    &.card-6 {
      margin-top: 70px;

      @media screen and (max-width: 1024px) {
        margin-top: 20px;
      }

      @include gt-md {

        @media screen and (max-height: 810px) {
          margin-top: 20px;
        }

      }
    }
  }
}
</style>
