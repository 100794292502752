<template>
  <section id="home-section-1" class="section">
    <div class="section__container">
      <div class="section__content">
        <div class="section__content--wrap">
          <div class="section__content--block1">
            <div style="position: relative">
              <div class="section__bg"></div>
              <div class="section__pill"></div>
            </div>
          </div>

          <div class="section__content--block2">
            <div class="">
              <div class="section__content--title">Алерон</div>

              <div class="section__content--subtitle">
                1 таблетка – 24 години д<span style="letter-spacing: 0.08em">ії</span> проти алерг<span style="letter-spacing: 0.08em">ії</span><sup>*2,3</sup>
              </div>

              <div class="section__content--description">
                Симптоматичне лікування алергічного риніту (зокрема цілорічного)
                та кропив’янки<sup>1</sup>
              </div>

              <div class="section__content--actions">
                <a target="_blank" href="https://tabletki.ua/%D0%90%D0%BB%D0%B5%D1%80%D0%BE%D0%BD/" class="btn" onclick="gtag('event', 'click', {  'event_category': 'Де придбати',  'event_label':'#home1'})">
                  Де придбати
                  <span class="btn__icon btn__icon--arrow"></span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="section__content--info">
          Реклама лікарського засобу АЛЕРОН таблетки, вкриті оболонкою (РП:
          UA/5615/01/01, наказ МОЗ №2032 від 04.09.2020), Перед застосуванням
          обов’язково ознайомтеся з інструкцією для медичного застосування та
          проконсультуйтеся з лікарем. Протипоказання та можливі побічні ефекти
          наведені в інструкц<span style="letter-spacing: 0.08em">ії</span> для медичного застосування лікарського засобу.
          Продається в аптеках без рецепта. Зберігати в недоступному для дітей
          місці. Представник виробника: ТОВ «Тева Україна», 01042, м. Київ,
          просп. П. Тичини, 1-В, поверх 9, +380 44 394-70-80; www.teva.ua.
          ALRN-UA-00083. Термін д<span style="letter-spacing: 0.08em">ії</span> матеріалу: 01.10.2025
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "section-1",
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.section {
  // padding-bottom: $footerSizeSm;

  min-height: calc((var(--vh, 1vh) * 100) - 20vh);
  margin-top: 0;

  @include gt-md {
    padding-top: $headerSize;
    padding-bottom: calc((var(--vh, 1vh) * 15) + 80px);

  }

  &__container {
    padding-bottom: 0;
  }

  &__content {

    &--wrap {
      border: 1px solid #eef233;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      min-height: calc((var(--vh, 1vh) * 100) - 240px);

      @media screen and (max-width: 390px) {
        min-height: calc((var(--vh, 1vh) * 100) - 260px);
      }

      @include gt-md {
        padding: 20px 0;
        min-height: calc((var(--vh, 1vh) * 85) - 200px);
        height: 100%;

        flex-direction: row;
        justify-content: initial;
      }
    }

    &--info {
      position: relative;
      z-index: 11;

      padding: 10px 0 0;
      text-align: justify;
      max-height: 95px;

      font-size: 8px;
      font-weight: 300;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #eef233;


      @include gt-md {
        font-size: 13px;
        max-height: 80px;
      }
    }

    &--block1 {
      position: relative;
      height: 160px;

      @include gt-md {
        order: 2;
        // width: 590px;
        height: 100%;
        // border-left: 1px solid #eef233;

        display: flex;
        align-items: center;

        & > div {
          width: 100%;
          height: 400px;
        }
      }
    }

    &--block2 {
      position: relative;
      z-index: 11;

      padding: 20px;
      width: 100%;

      @include gt-md {
        order: 1;
        border-right: 1px solid #eef233;
        padding: 25px 55px 60px;

        width: 500px;
        height: 100%;

        display: flex;
        align-items: center;
      }
    }

    &--title {
      @include Ubuntu-700;
      font-size: 30px;
      line-height: 36px;

      @include gt-md {
        font-size: 66px;
        line-height: 76px;
      }
    }

    &--subtitle {
      margin-top: 5px;

      @include Ubuntu-700;
      font-size: 19px;
      line-height: 120%;

      @media screen and (min-width: 390px) {
        font-size: 21px;
      }

      @include gt-md {
        font-size: 32px;
      }
    }

    &--description {
      margin-top: 15px;
      margin-bottom: 15px;

      font-size: 13px;
      line-height: 130%;


      @media screen and (min-width: 390px) {
        font-size: 15px;
      }

      @include gt-md {
        font-size: 18px;
        line-height: 24px;
        width: 100%;
      }
    }

    &--actions {
      margin: auto 0;
      margin-top: 20px;

      @include gt-md {
        margin-top: 70px;

        @media screen and (max-height: 660px) {
          margin-top: 30px;
        }
      }
    }
  }

  &__bg {
    z-index: 10;
    position: absolute;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;

    background-image: url("@/assets/home_icon/packs-mob-1-min.png");
    width: calc(479px + 10%);
    height: calc(450px + 10%);
    top: -100px;
    left: calc(-124px);

    @include gt-md {
      background-image: url("@/assets/home_icon/packs-des-1-min.png");
      width: 1024px;
      height: 1022px;
      top: -280px;
      left: -300px;

      @media screen and (max-height: 660px) {
        width: calc(1024px - 30%);
        height: calc(1022px - 30%);

        top: -250px;
        left: -250px;
      }
      @media screen and (max-height: 630px) {
        width: calc(1024px - 50%);
        height: calc(1022px - 50%);

        top: -210px;
        left: -200px;
      }
      @media screen and (max-height: 600px) {
        width: calc(1024px - 60%);
        height: calc(1022px - 60%);

        top: -170px;
        left: -200px;
      }
    }
  }

  &__pill {
    position: absolute;
    z-index: 11;

    top: 25px;
    left: 30px;
    width: 76px;
    height: 76px;
    background-color: #ffffff;
    border-radius: 50%;
    overflow: hidden;

    @include gt-md {
      top: 20px;
      left: 60px;
      width: 124px;
      height: 124px;
      @media screen and (max-height: 660px) {
        width: 120px;
        height: 120px;
      }
      @media screen and (max-height: 630px) {
        width: 100px;
        height: 100px;

        top: 30px;
        left: 100px;
      }
      @media screen and (max-height: 600px) {
        top: 60px;
        left: 80px;
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }

    &::before {
      background-image: url("@/assets/icon/pill.svg");
      background-size: 33px 25px;

      @include gt-md {
        background-size: 45px 40px;


      }
    }

    &::after {
      background-image: url("@/assets/icon/pill_text.svg");
      animation: 10s rotate linear infinite;
      background-size: 65px 65px;

      @include gt-md {
        background-size: 109px 104px;

        @media screen and (max-height: 630px) {
          background-size: 92px 85px;
        }
      }
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  min-width: 185px;
  padding: 12px 20px;
  background-color: #eef233;
  border-radius: 41px;
  text-decoration: none;

  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  color: #0d783d;

  @media screen and (min-width: 390px) {
    font-size: 15px;
  }

  @include gt-md {
    font-size: 18px;
    line-height: 24px;

    min-width: 220px;
    padding: 13px 20px;
  }

  &:active {
    background-color: #ffffff;
  }

  @media screen and (min-width: 1024px) {
    &:hover {
      background-color: #ffffff;
    }
  }

  &__icon {
    margin-left: 20px;

    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;

    width: 14px;
    height: 14px;

    &--arrow {
      width: 16px;
      min-height: 16px;
      background-image: url("@/assets/icon/arrow-right.svg");
    }
  }
}
</style>
