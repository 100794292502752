<template>
  <div class="self-med">
    <svg
        class="desk"
        viewBox="0 0 1856 133"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M29.93 30.9055V40.2265H27.683V30.7405C27.683 16.1403 24.4591 3.02487 15.1779 3.02487C5.7991 3.02487 2.57513 16.1403 2.57513 30.7405V92.3583C2.57513 106.958 5.7991 119.909 15.1779 119.909C24.4591 119.909 27.683 106.958 27.683 92.3583V79.0779H29.93V92.1933C29.93 108.856 25.9245 122.631 15.0802 122.631C4.33366 122.631 0.328125 108.856 0.328125 92.1933V30.9055C0.328125 14.2431 4.33366 0.467773 15.0802 0.467773C25.8268 0.467773 29.93 14.2431 29.93 30.9055Z"
          fill="#626262"
      />
      <path
          d="M40.5782 92.5235L36.475 121.724H34.4233L51.5201 1.12793H54.6464L72.134 121.724H69.887L65.7838 92.5235H40.5782ZM40.969 89.9664H65.2953L52.9856 5.66471L40.969 89.9664Z"
          fill="#626262"
      />
      <path
          d="M98.903 121.394H96.3629L78.8753 6.98457V121.724H76.8237V1.29297H80.2431L97.633 115.537L115.023 1.29297H118.442V121.724H116.195V6.98457L98.903 121.394Z"
          fill="#626262"
      />
      <path
          d="M123.424 30.9055C123.424 14.0781 127.625 0.467773 138.372 0.467773C149.314 0.467773 153.515 14.0781 153.515 30.9055V92.1933C153.515 109.021 149.314 122.631 138.372 122.631C127.625 122.631 123.424 109.021 123.424 92.1933V30.9055V30.9055ZM125.671 92.3583C125.671 107.123 128.993 120.074 138.372 120.074C147.848 120.074 151.268 107.123 151.268 92.3583V30.7405C151.268 15.9753 147.848 3.02487 138.372 3.02487C128.993 3.02487 125.671 15.9753 125.671 30.7405V92.3583Z"
          fill="#626262"
      />
      <path
          d="M171.785 4.09753L169.734 92.6886C169.343 106.959 168.855 121.394 158.694 121.971H158.01V119.249C167.194 119.249 167.291 104.979 167.487 92.771L169.636 1.29297H194.158V121.724H191.813V4.09753H171.785Z"
          fill="#626262"
      />
      <path
          d="M201.484 1.29297V121.724H199.237V1.29297H201.484Z"
          fill="#626262"
      />
      <path
          d="M214.087 61.9209L208.714 75.6962V121.724H206.467V1.29297H208.714V69.2622L234.994 1.29297H237.436L215.553 58.209L239.293 121.724H236.753L214.087 61.9209Z"
          fill="#626262"
      />
      <path
          d="M259.32 102.505C257.073 114.383 254.63 121.971 246.424 121.806V119.249C253.067 119.249 255.119 112.32 257.171 102.505L261.176 82.8726L243.591 1.29297H245.936L262.251 77.8409L268.992 43.9387L277.589 1.29297H280.031L259.32 102.505Z"
          fill="#626262"
      />
      <path
          d="M298.789 1.29297C309.048 1.29297 312.467 10.0366 312.467 26.204V33.9578C312.467 47.4032 310.611 55.6519 302.697 57.7141C311.099 59.7762 313.932 69.4272 313.932 83.3675V94.0083C313.932 111.001 309.731 121.724 298.985 121.724H284.624V1.29297H298.789ZM296.054 56.8892C305.824 56.8892 310.22 52.0225 310.22 34.5352V26.4515C310.22 12.3462 307.68 4.09753 298.789 4.09753H286.871V56.9717H296.054V56.8892ZM298.887 119.002C308.168 119.002 311.588 109.186 311.588 94.0083V83.2025C311.588 65.7978 306.703 59.4463 297.031 59.4463H286.871V119.002H298.887Z"
          fill="#626262"
      />
      <path
          d="M324.484 92.5235L320.381 121.806H318.329L335.426 1.12793H338.552L356.04 121.724H353.793L349.69 92.441H324.484V92.5235ZM324.972 89.9664H349.299L336.989 5.66471L324.972 89.9664Z"
          fill="#626262"
      />
      <path
          d="M362.976 63.2407V121.724H360.827V1.29297H363.074V60.6836H388.572V1.29297H390.819V121.724H388.572V63.2407H362.976Z"
          fill="#626262"
      />
      <path
          d="M398.05 63.2407V121.724H395.803V1.29297H398.05V60.6836H423.548V1.29297H425.795V121.724H423.548V63.2407H398.05Z"
          fill="#626262"
      />
      <path
          d="M450.218 66.2098C441.035 66.2098 434.782 70.829 434.782 88.5637V107.453C434.782 112.65 434.489 117.929 433.023 121.724H430.581C432.242 117.929 432.535 112.073 432.535 107.453V88.5637C432.535 75.2833 435.857 66.5397 443.77 64.4775C435.954 62.0854 432.535 54.0017 432.535 39.154V28.3482C432.535 11.8508 436.54 1.375 447.092 1.375H461.16V121.806H458.913V66.2098H450.218ZM447.189 4.09707C438.104 4.09707 434.782 14.078 434.782 28.7607V39.7314C434.782 58.291 440.448 63.4877 450.218 63.4877H458.913V4.09707H447.189V4.09707Z"
          fill="#626262"
      />
      <path
          d="M508.934 121.394H506.394L488.907 6.98457V121.724H486.855V1.29297H490.274L507.664 115.537L525.054 1.29297H528.473V121.724H526.226V6.98457L508.934 121.394Z"
          fill="#626262"
      />
      <path
          d="M533.456 30.9055C533.456 14.0781 537.656 0.467773 548.403 0.467773C559.345 0.467773 563.546 14.0781 563.546 30.9055V92.1933C563.546 109.021 559.345 122.631 548.403 122.631C537.656 122.631 533.456 109.021 533.456 92.1933V30.9055ZM535.605 92.3583C535.605 107.123 538.927 120.074 548.305 120.074C557.782 120.074 561.201 107.123 561.201 92.3583V30.7405C561.201 15.9753 557.782 3.02487 548.305 3.02487C538.927 3.02487 535.605 15.9753 535.605 30.7405V92.3583Z"
          fill="#626262"
      />
      <path
          d="M602.818 58.9514L597.25 74.2939V121.724H595.003V74.2939L589.629 58.9514L570.383 121.724H568.039L588.262 55.4869L569.211 1.29297H571.556L590.704 55.4869L595.003 68.1899V1.29297H597.25V68.1899L601.646 55.0745L620.892 1.29297H623.335L604.284 55.4869L624.409 121.724H622.065L602.818 58.9514Z"
          fill="#626262"
      />
      <path
          d="M652.057 58.9514V61.5085H631.247V119.002H655.965V121.724H629V1.29297H655.965V4.01504H631.247V58.9514H652.057V58.9514Z"
          fill="#626262"
      />
      <path
          d="M695.143 54.662C706.085 54.662 711.458 60.3536 711.458 79.5731V93.5134C711.458 111.248 707.16 121.724 696.218 121.724H681.466V1.29297H709.016V4.01504H683.713V54.662H695.143ZM696.316 119.002C705.792 119.002 709.309 109.186 709.309 93.6784V79.4081C709.309 62.1684 704.62 57.3841 695.143 57.3841H683.713V119.002H696.316Z"
          fill="#626262"
      />
      <path
          d="M731.876 102.505C729.629 114.383 727.186 121.971 718.98 121.806V119.249C725.623 119.249 727.675 112.32 729.726 102.505L733.732 82.8726L716.049 1.29297H718.394L734.709 77.8409L741.45 43.9387L750.145 1.29297H752.587L731.876 102.505Z"
          fill="#626262"
      />
      <path
          d="M772.225 121.724V4.09753H756.789V1.29297H789.908V4.01504H774.472V121.724H772.225Z"
          fill="#626262"
      />
      <path
          d="M809.642 64.643L797.235 121.724H794.5V1.29297H796.649V114.465L821.561 1.29297H824.59V121.724H822.441V7.14954L809.642 64.643Z"
          fill="#626262"
      />
      <path
          d="M852.627 1.29297V119.002H873.046V1.29297H875.293V119.002H895.711V1.29297H897.958V121.724H850.478V1.29297H852.627Z"
          fill="#626262"
      />
      <path
          d="M910.465 61.9209L905.092 75.6962V121.724H902.845V1.29297H905.092V69.2622L931.372 1.29297H933.815L911.931 58.209L935.671 121.724H933.229L910.465 61.9209Z"
          fill="#626262"
      />
      <path
          d="M942.509 1.29297V121.724H940.262V1.29297H942.509Z"
          fill="#626262"
      />
      <path
          d="M986.96 132.447V120.899H949.542V132.447H947.295V118.177H952.376C955.502 112.815 955.893 105.639 956.088 97.5548L959.019 1.375H982.954V118.177H989.207V132.447H986.96ZM958.335 97.6373C958.042 105.556 957.847 112.567 954.916 118.094H980.805V4.09707H961.266L958.335 97.6373Z"
          fill="#626262"
      />
      <path
          d="M1007.38 4.09753L1005.33 92.6886C1004.94 106.959 1004.45 121.394 994.287 121.971H993.604V119.249C1002.79 119.249 1002.88 104.979 1003.08 92.771L1005.23 1.29297H1029.75V121.724H1027.41V4.09753H1007.38Z"
          fill="#626262"
      />
      <path
          d="M1049.97 64.643L1037.57 121.724H1034.83V1.29297H1036.98V114.465L1061.89 1.29297H1064.92V121.724H1062.77V7.14954L1049.97 64.643Z"
          fill="#626262"
      />
      <path
          d="M1084.07 1.29297C1094.33 1.29297 1097.75 10.0366 1097.75 26.204V33.9578C1097.75 47.4032 1095.89 55.6519 1087.98 57.7141C1096.38 59.7762 1099.21 69.4272 1099.21 83.3675V94.0083C1099.21 111.001 1095.01 121.724 1084.26 121.724H1070V1.29297H1084.07ZM1081.33 56.8892C1091.1 56.8892 1095.5 52.0225 1095.5 34.5352V26.4515C1095.5 12.3462 1092.96 4.09753 1084.07 4.09753H1072.15V56.9717H1081.33V56.8892ZM1084.17 119.002C1093.45 119.002 1096.87 109.186 1096.87 94.0083V83.2025C1096.87 65.7978 1091.98 59.4463 1082.31 59.4463H1072.15V119.002H1084.17Z"
          fill="#626262"
      />
      <path
          d="M1119.14 64.643L1106.74 121.724H1104V1.29297H1106.15V114.465L1131.06 1.29297H1134.09V121.724H1131.94V7.14954L1119.14 64.643Z"
          fill="#626262"
      />
      <path
          d="M1161.15 121.394H1158.61L1141.12 6.98457V121.724H1139.07V1.29297H1142.49L1159.88 115.537L1177.27 1.29297H1180.69V121.724H1178.44V6.98457L1161.15 121.394Z"
          fill="#626262"
      />
      <path
          d="M1245.85 132.447V120.899H1208.44V132.447H1206.19V118.177H1211.27C1214.4 112.815 1214.79 105.639 1214.98 97.5548L1217.91 1.375H1241.85V118.177H1248.1V132.447H1245.85ZM1217.13 97.6373C1216.84 105.556 1216.64 112.567 1213.71 118.094H1239.6V4.09707H1220.06L1217.13 97.6373Z"
          fill="#626262"
      />
      <path
          d="M1266.27 4.09753L1264.22 92.6886C1263.83 106.959 1263.34 121.394 1253.18 121.971H1252.5V119.249C1261.68 119.249 1261.78 104.979 1261.97 92.771L1264.12 1.29297H1288.65V121.724H1286.3V4.09753H1266.27Z"
          fill="#626262"
      />
      <path
          d="M1313.07 66.2098C1303.89 66.2098 1297.63 70.829 1297.63 88.5637V107.453C1297.63 112.65 1297.34 117.929 1295.88 121.724H1293.43C1295.09 117.929 1295.39 112.073 1295.39 107.453V88.5637C1295.39 75.2833 1298.71 66.5397 1306.62 64.4775C1298.81 62.0854 1295.39 54.0017 1295.39 39.154V28.3482C1295.39 11.8508 1299.39 1.375 1309.94 1.375H1324.01V121.806H1321.76V66.2098H1313.07ZM1310.04 4.09707C1300.96 4.09707 1297.63 14.078 1297.63 28.7607V39.7314C1297.63 58.291 1303.3 63.4877 1313.07 63.4877H1321.76V4.09707H1310.04V4.09707Z"
          fill="#626262"
      />
      <path
          d="M1363.87 1.29297C1374.13 1.29297 1377.55 10.0366 1377.55 26.204V33.9578C1377.55 47.4032 1375.69 55.6519 1367.78 57.7141C1376.18 59.7762 1379.01 69.4272 1379.01 83.3675V94.0083C1379.01 111.001 1374.81 121.724 1364.07 121.724H1349.8V1.29297H1363.87ZM1361.14 56.8892C1370.91 56.8892 1375.3 52.0225 1375.3 34.5352V26.4515C1375.3 12.3462 1372.76 4.09753 1363.87 4.09753H1351.95V56.9717H1361.14V56.8892ZM1363.97 119.002C1373.25 119.002 1376.67 109.186 1376.67 94.0083V83.2025C1376.67 65.7978 1371.79 59.4463 1362.11 59.4463H1351.95V119.002H1363.97Z"
          fill="#626262"
      />
      <path
          d="M1389.66 92.5231L1385.56 121.806H1383.51L1400.6 1.20996H1403.73L1421.22 121.806H1418.97L1414.87 92.5231H1389.66ZM1390.05 89.966H1414.38L1402.07 5.66426L1390.05 89.966Z"
          fill="#626262"
      />
      <path
          d="M1428.06 1.29297V119.002H1448.48V1.29297H1450.72V119.002H1471.14V1.29297H1473.39V121.724H1425.91V1.29297H1428.06Z"
          fill="#626262"
      />
      <path
          d="M1478.27 30.9055C1478.27 14.0781 1482.47 0.467773 1493.22 0.467773C1504.16 0.467773 1508.36 14.0781 1508.36 30.9055V92.1933C1508.36 109.021 1504.16 122.631 1493.22 122.631C1482.47 122.631 1478.27 109.021 1478.27 92.1933V30.9055ZM1480.52 92.3583C1480.52 107.123 1483.84 120.074 1493.22 120.074C1502.7 120.074 1506.12 107.123 1506.12 92.3583V30.7405C1506.12 15.9753 1502.7 3.02487 1493.22 3.02487C1483.84 3.02487 1480.52 15.9753 1480.52 30.7405V92.3583Z"
          fill="#626262"
      />
      <path
          d="M1515.5 4.09753V121.724H1513.25V1.29297H1539.53V4.01504H1515.5V4.09753Z"
          fill="#626262"
      />
      <path
          d="M1544.41 30.9055C1544.41 14.0781 1548.62 0.467773 1559.36 0.467773C1570.3 0.467773 1574.5 14.0781 1574.5 30.9055V92.1933C1574.5 109.021 1570.3 122.631 1559.36 122.631C1548.62 122.631 1544.41 109.021 1544.41 92.1933V30.9055ZM1546.66 92.3583C1546.66 107.123 1549.98 120.074 1559.36 120.074C1568.84 120.074 1572.26 107.123 1572.26 92.3583V30.7405C1572.26 15.9753 1568.84 3.02487 1559.36 3.02487C1549.98 3.02487 1546.66 15.9753 1546.66 30.7405V92.3583Z"
          fill="#626262"
      />
      <path
          d="M1629.6 30.9055V34.04C1629.6 45.5882 1625.79 55.5691 1619.35 58.7861C1626.18 61.5081 1629.6 71.3241 1629.6 82.8723V92.1933C1629.6 109.021 1625.7 122.631 1614.85 122.631C1604.01 122.631 1600 109.021 1600 92.1933V83.4497H1602.25V92.3583C1602.25 106.958 1605.38 120.074 1614.75 120.074C1624.13 120.074 1627.36 106.958 1627.36 92.1933V82.8723C1627.36 70.1693 1622.57 60.6833 1615.14 60.6833H1610.55V57.2188H1615.24C1622.28 57.2188 1627.36 46.413 1627.36 34.5349V30.9055C1627.36 15.9753 1624.13 3.02487 1614.75 3.02487C1605.38 3.02487 1602.15 15.9753 1602.15 30.7405V35.9372H1600.1V30.7405C1600.1 14.0781 1604.01 0.467773 1614.85 0.467773C1625.7 0.467773 1629.6 14.0781 1629.6 30.9055Z"
          fill="#626262"
      />
      <path
          d="M1673.96 132.447V120.899H1636.54V132.447H1634.29V118.177H1639.37C1642.5 112.815 1642.89 105.639 1643.09 97.5548L1646.02 1.375H1669.95V118.177H1676.2V132.447H1673.96ZM1645.23 97.6373C1644.94 105.556 1644.75 112.567 1641.82 118.094H1667.7V4.09707H1648.17L1645.23 97.6373Z"
          fill="#626262"
      />
      <path
          d="M1680.9 30.9055C1680.9 14.0781 1685.1 0.467773 1695.84 0.467773C1706.79 0.467773 1710.99 14.0781 1710.99 30.9055V92.1933C1710.99 109.021 1706.79 122.631 1695.84 122.631C1685.1 122.631 1680.9 109.021 1680.9 92.1933V30.9055ZM1683.14 92.3583C1683.14 107.123 1686.47 120.074 1695.84 120.074C1705.32 120.074 1708.74 107.123 1708.74 92.3583V30.7405C1708.74 15.9753 1705.32 3.02487 1695.84 3.02487C1686.47 3.02487 1683.14 15.9753 1683.14 30.7405V92.3583Z"
          fill="#626262"
      />
      <path
          d="M1729.84 1.29297C1740.78 1.29297 1744.49 13.501 1744.49 30.3284V42.3715C1744.49 61.5085 1739.41 70.582 1728.28 70.582H1718.21V121.724H1715.97V1.29297H1729.84ZM1728.28 67.9425C1738.14 67.9425 1742.34 59.6938 1742.34 42.6189V30.2459C1742.34 15.4807 1739.41 4.09753 1729.94 4.09753H1718.21V67.9425H1728.28Z"
          fill="#626262"
      />
      <path
          d="M1749.09 30.9055C1749.09 14.0781 1753.29 0.467773 1764.03 0.467773C1774.98 0.467773 1779.18 14.0781 1779.18 30.9055V92.1933C1779.18 109.021 1774.98 122.631 1764.03 122.631C1753.29 122.631 1749.09 109.021 1749.09 92.1933V30.9055ZM1751.33 92.3583C1751.33 107.123 1754.66 120.074 1764.03 120.074C1773.51 120.074 1776.93 107.123 1776.93 92.3583V30.7405C1776.93 15.9753 1773.51 3.02487 1764.03 3.02487C1754.66 3.02487 1751.33 15.9753 1751.33 30.7405V92.3583Z"
          fill="#626262"
      />
      <path
          d="M1798.23 1.29297C1808.49 1.29297 1811.9 10.0366 1811.9 26.204V33.9578C1811.9 47.4032 1810.05 55.6519 1802.14 57.7141C1810.54 59.7762 1813.37 69.4272 1813.37 83.3675V94.0083C1813.37 111.001 1809.17 121.724 1798.42 121.724H1784.16V1.29297H1798.23ZM1795.49 56.8892C1805.26 56.8892 1809.66 52.0225 1809.66 34.5352V26.4515C1809.66 12.3462 1807.12 4.09753 1798.23 4.09753H1786.31V56.9717H1795.49V56.8892ZM1798.33 119.002C1807.61 119.002 1811.03 109.186 1811.03 94.0083V83.2025C1811.03 65.7978 1806.14 59.4463 1796.47 59.4463H1786.31V119.002H1798.33Z"
          fill="#626262"
      />
      <path
          d="M1820.21 1.12793L1820.01 28.8436H1818.16L1818.06 1.12793H1820.21V1.12793Z"
          fill="#626262"
      />
      <path
          d="M1844.73 66.2098C1835.55 66.2098 1829.29 70.829 1829.29 88.5637V107.453C1829.29 112.65 1829 117.929 1827.54 121.724H1825.09C1826.75 117.929 1827.05 112.073 1827.05 107.453V88.5637C1827.05 75.2833 1830.37 66.5397 1838.28 64.4775C1830.47 62.0854 1827.05 54.0017 1827.05 39.154V28.3482C1827.05 11.8508 1831.05 1.375 1841.6 1.375H1855.67V121.806H1853.43V66.2098H1844.73ZM1841.7 4.09707C1832.62 4.09707 1829.29 14.078 1829.29 28.7607V39.7314C1829.29 58.291 1834.96 63.4877 1844.73 63.4877H1853.43V4.09707H1841.7V4.09707Z"
          fill="#626262"
      />
    </svg>

    <svg
        class="mob"
        viewBox="0 0 330 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M11.8514 8.53312V11.1462H10.9518V8.48687C10.9518 4.39375 9.66102 0.716875 5.94524 0.716875C2.19035 0.716875 0.899609 4.39375 0.899609 8.48687V25.7612C0.899609 29.8544 2.19035 33.485 5.94524 33.485C9.66102 33.485 10.9518 29.8544 10.9518 25.7612V22.0381H11.8514V25.715C11.8514 30.3862 10.2477 34.2481 5.90613 34.2481C1.60365 34.2481 0 30.3862 0 25.715V8.53312C0 3.86187 1.60365 0 5.90613 0C10.2086 0 11.8514 3.86187 11.8514 8.53312Z"
          fill="#626262"
      />
      <path
          d="M16.1145 25.8075L14.4718 33.9938H13.6504L20.4952 0.185059H21.7469L28.7482 33.9938H27.8486L26.2058 25.8075H16.1145ZM16.271 25.0907H26.0102L21.0819 1.45693L16.271 25.0907Z"
          fill="#626262"
      />
      <path
          d="M39.4646 33.9012H38.4477L31.4464 1.82683V33.9937H30.625V0.231201H31.994L38.9562 32.2593L45.9183 0.231201H47.2873V33.9937H46.3877V1.82683L39.4646 33.9012Z"
          fill="#626262"
      />
      <path
          d="M49.2832 8.53312C49.2832 3.81562 50.9651 0 55.2676 0C59.6483 0 61.3301 3.81562 61.3301 8.53312V25.715C61.3301 30.4325 59.6483 34.2481 55.2676 34.2481C50.9651 34.2481 49.2832 30.4325 49.2832 25.715V8.53312V8.53312ZM50.1828 25.7612C50.1828 29.9006 51.5127 33.5312 55.2676 33.5312C59.0616 33.5312 60.4305 29.9006 60.4305 25.7612V8.48687C60.4305 4.3475 59.0616 0.716875 55.2676 0.716875C51.5127 0.716875 50.1828 4.3475 50.1828 8.48687V25.7612Z"
          fill="#626262"
      />
      <path
          d="M68.6439 1.01745L67.8225 25.8537C67.6661 29.8543 67.4705 33.9012 63.4027 34.0631H63.1289V33.2999C66.8056 33.2999 66.8447 29.2993 66.9229 25.8768L67.7834 0.231201H77.6009V33.9937H76.6622V1.01745H68.6439Z"
          fill="#626262"
      />
      <path
          d="M80.5344 0.231201V33.9937H79.6348V0.231201H80.5344Z"
          fill="#626262"
      />
      <path
          d="M85.5801 17.2281L83.4289 21.0899V33.9937H82.5293V0.231201H83.4289V19.2862L93.9504 0.231201H94.9282L86.1668 16.1874L95.6714 33.9937H94.6544L85.5801 17.2281Z"
          fill="#626262"
      />
      <path
          d="M103.69 28.6056C102.79 31.9356 101.812 34.0631 98.5269 34.0168V33.2999C101.187 33.2999 102.008 31.3574 102.829 28.6056L104.433 23.1018L97.3926 0.231201H98.3313L104.863 21.6912L107.562 12.1868L111.004 0.231201H111.982L103.69 28.6056Z"
          fill="#626262"
      />
      <path
          d="M119.492 0.231201C123.599 0.231201 124.968 2.68245 124.968 7.21495V9.3887C124.968 13.1581 124.224 15.4706 121.056 16.0487C124.42 16.6268 125.554 19.3324 125.554 23.2406V26.2237C125.554 30.9874 123.872 33.9937 119.57 33.9937H113.82V0.231201H119.492ZM118.397 15.8174C122.308 15.8174 124.068 14.4531 124.068 9.55057V7.28432C124.068 3.32995 123.051 1.01745 119.492 1.01745H114.72V15.8406H118.397V15.8174ZM119.531 33.2306C123.247 33.2306 124.616 30.4787 124.616 26.2237V23.1943C124.616 18.3149 122.66 16.5343 118.788 16.5343H114.72V33.2306H119.531Z"
          fill="#626262"
      />
      <path
          d="M129.779 25.8075L128.136 34.0169H127.314L134.159 0.185059H135.411L142.412 33.9938H141.513L139.87 25.7844H129.779V25.8075ZM129.974 25.0907H139.713L134.785 1.45693L129.974 25.0907Z"
          fill="#626262"
      />
      <path
          d="M145.189 17.5981V33.9937H144.328V0.231201H145.228V16.8812H155.436V0.231201H156.336V33.9937H155.436V17.5981H145.189Z"
          fill="#626262"
      />
      <path
          d="M159.232 17.5981V33.9937H158.332V0.231201H159.232V16.8812H169.44V0.231201H170.34V33.9937H169.44V17.5981H159.232Z"
          fill="#626262"
      />
      <path
          d="M180.118 18.4306C176.441 18.4306 173.938 19.7256 173.938 24.6975V29.9931C173.938 31.45 173.82 32.93 173.234 33.9937H172.256C172.921 32.93 173.038 31.2881 173.038 29.9931V24.6975C173.038 20.9744 174.368 18.5231 177.536 17.945C174.407 17.2744 173.038 15.0081 173.038 10.8456V7.81627C173.038 3.19127 174.642 0.254395 178.866 0.254395H184.498V34.0169H183.599V18.4306H180.118ZM178.905 1.01752C175.268 1.01752 173.938 3.81564 173.938 7.93189V11.0075C173.938 16.2106 176.206 17.6675 180.118 17.6675H183.599V1.01752H178.905V1.01752Z"
          fill="#626262"
      />
      <path
          d="M203.625 33.9012H202.608L195.607 1.82683V33.9937H194.785V0.231201H196.154L203.116 32.2593L210.078 0.231201H211.447V33.9937H210.548V1.82683L203.625 33.9012Z"
          fill="#626262"
      />
      <path
          d="M213.441 8.53312C213.441 3.81562 215.123 0 219.426 0C223.806 0 225.488 3.81562 225.488 8.53312V25.715C225.488 30.4325 223.806 34.2481 219.426 34.2481C215.123 34.2481 213.441 30.4325 213.441 25.715V8.53312ZM214.302 25.7612C214.302 29.9006 215.632 33.5312 219.387 33.5312C223.181 33.5312 224.55 29.9006 224.55 25.7612V8.48687C224.55 4.3475 223.181 0.716875 219.387 0.716875C215.632 0.716875 214.302 4.3475 214.302 8.48687V25.7612Z"
          fill="#626262"
      />
      <path
          d="M241.211 16.3956L238.982 20.6968V33.9937H238.082V20.6968L235.931 16.3956L228.226 33.9937H227.287L235.384 15.4243L227.756 0.231201H228.695L236.361 15.4243L238.082 18.9856V0.231201H238.982V18.9856L240.742 15.3087L248.447 0.231201H249.425L241.798 15.4243L249.856 33.9937H248.917L241.211 16.3956Z"
          fill="#626262"
      />
      <path
          d="M260.924 16.3956V17.1124H252.593V33.2306H262.489V33.9937H251.693V0.231201H262.489V0.994326H252.593V16.3956H260.924V16.3956Z"
          fill="#626262"
      />
      <path
          d="M278.175 15.1931C282.556 15.1931 284.707 16.7887 284.707 22.1768V26.0849C284.707 31.0568 282.986 33.9937 278.605 33.9937H272.699V0.231201H283.729V0.994326H273.599V15.1931H278.175ZM278.644 33.2306C282.438 33.2306 283.847 30.4787 283.847 26.1312V22.1306C283.847 17.2974 281.969 15.9562 278.175 15.9562H273.599V33.2306H278.644Z"
          fill="#626262"
      />
      <path
          d="M292.881 28.6056C291.982 31.9356 291.004 34.0631 287.718 34.0168V33.2999C290.378 33.2999 291.199 31.3574 292.021 28.6056L293.624 23.1018L286.545 0.231201H287.484L294.016 21.6912L296.714 12.1868L300.196 0.231201H301.173L292.881 28.6056Z"
          fill="#626262"
      />
      <path
          d="M309.035 33.9937V1.01745H302.855V0.231201H316.115V0.994326H309.935V33.9937H309.035Z"
          fill="#626262"
      />
      <path
          d="M324.016 17.9912L319.048 33.9937H317.953V0.231201H318.814V31.9587L328.788 0.231201H330V33.9937H329.14V1.87308L324.016 17.9912Z"
          fill="#626262"
      />
      <path
          d="M0.873221 37.2312V70.2306H7.57307V37.2312H8.31037V70.2306H15.0102V37.2312H15.7475V70.9937H0.167969V37.2312H0.873221Z"
          fill="#626262"
      />
      <path
          d="M19.852 54.2281L18.0889 58.0899V70.9937H17.3516V37.2312H18.0889V56.2862L26.7121 37.2312H27.5136L20.3328 53.1874L28.1226 70.9937H27.3212L19.852 54.2281Z"
          fill="#626262"
      />
      <path
          d="M30.3662 37.2312V70.9937H29.6289V37.2312H30.3662Z"
          fill="#626262"
      />
      <path
          d="M44.9526 74V70.7625H32.6748V74H31.9375V69.9994H33.6045C34.6303 68.4963 34.7585 66.4844 34.8226 64.2181L35.7843 37.2544H43.6382V69.9994H45.6899V74H44.9526ZM35.5599 64.2413C35.4637 66.4613 35.3996 68.4269 34.4379 69.9763H42.933V38.0175H36.5216L35.5599 64.2413Z"
          fill="#626262"
      />
      <path
          d="M51.6528 38.0175L50.9796 62.8537C50.8514 66.8543 50.6911 70.9012 47.3572 71.0631H47.1328V70.2999C50.1462 70.2999 50.1782 66.2993 50.2423 62.8768L50.9476 37.2312H58.9938V70.9937H58.2245V38.0175H51.6528Z"
          fill="#626262"
      />
      <path
          d="M65.627 54.9912L61.5558 70.9937H60.6582V37.2312H61.3634V68.9587L69.5379 37.2312H70.5316V70.9937H69.8264V38.8731L65.627 54.9912Z"
          fill="#626262"
      />
      <path
          d="M76.8154 37.2312C80.1814 37.2312 81.3034 39.6824 81.3034 44.2149V46.3887C81.3034 50.1581 80.6943 52.4706 78.0977 53.0487C80.8546 53.6268 81.7842 56.3324 81.7842 60.2406V63.2237C81.7842 67.9874 80.4057 70.9937 76.8795 70.9937H72.1992V37.2312H76.8154ZM75.9178 52.8174C79.1235 52.8174 80.566 51.4531 80.566 46.5506V44.2843C80.566 40.3299 79.7326 38.0175 76.8154 38.0175H72.9045V52.8406H75.9178V52.8174ZM76.8475 70.2306C79.8928 70.2306 81.0148 67.4787 81.0148 63.2237V60.1943C81.0148 55.3149 79.412 53.5343 76.2384 53.5343H72.9045V70.2306H76.8475Z"
          fill="#626262"
      />
      <path
          d="M88.3243 54.9912L84.2531 70.9937H83.3555V37.2312H84.0607V68.9587L92.2352 37.2312H93.229V70.9937H92.5237V38.8731L88.3243 54.9912Z"
          fill="#626262"
      />
      <path
          d="M102.108 70.9012H101.275L95.5365 38.8268V70.9937H94.8633V37.2312H95.9853L101.691 69.2593L107.397 37.2312H108.519V70.9937H107.782V38.8268L102.108 70.9012Z"
          fill="#626262"
      />
      <path
          d="M129.902 74V70.7625H117.624V74H116.887V69.9994H118.554C119.579 68.4963 119.708 66.4844 119.772 64.2181L120.734 37.2544H128.587V69.9994H130.639V74H129.902ZM120.477 64.2413C120.381 66.4613 120.317 68.4269 119.355 69.9763H127.85V38.0175H121.439L120.477 64.2413Z"
          fill="#626262"
      />
      <path
          d="M136.602 38.0175L135.929 62.8537C135.801 66.8543 135.64 70.9012 132.306 71.0631H132.082V70.2999C135.095 70.2999 135.127 66.2993 135.192 62.8768L135.897 37.2312H143.943V70.9937H143.174V38.0175H136.602Z"
          fill="#626262"
      />
      <path
          d="M151.957 55.4306C148.944 55.4306 146.892 56.7256 146.892 61.6975V66.9931C146.892 68.45 146.796 69.93 146.315 70.9937H145.514C146.059 69.93 146.155 68.2881 146.155 66.9931V61.6975C146.155 57.9744 147.245 55.5231 149.841 54.945C147.277 54.2744 146.155 52.0081 146.155 47.8456V44.8163C146.155 40.1913 147.469 37.2544 150.931 37.2544H155.547V71.0169H154.81V55.4306H151.957ZM150.963 38.0175C147.982 38.0175 146.892 40.8156 146.892 44.9319V48.0075C146.892 53.2106 148.751 54.6675 151.957 54.6675H154.81V38.0175H150.963V38.0175Z"
          fill="#626262"
      />
      <path
          d="M168.626 37.2312C171.992 37.2312 173.114 39.6824 173.114 44.2149V46.3887C173.114 50.1581 172.505 52.4706 169.908 53.0487C172.665 53.6268 173.595 56.3324 173.595 60.2406V63.2237C173.595 67.9874 172.216 70.9937 168.69 70.9937H164.01V37.2312H168.626ZM167.728 52.8174C170.934 52.8174 172.377 51.4531 172.377 46.5506V44.2843C172.377 40.3299 171.543 38.0175 168.626 38.0175H164.715V52.8406H167.728V52.8174ZM168.658 70.2306C171.703 70.2306 172.825 67.4787 172.825 63.2237V60.1943C172.825 55.3149 171.223 53.5343 168.049 53.5343H164.715V70.2306H168.658Z"
          fill="#626262"
      />
      <path
          d="M177.09 62.8075L175.743 71.0169H175.07L180.68 37.2081H181.706L187.444 71.0169H186.707L185.361 62.8075H177.09ZM177.218 62.0906H185.2L181.161 38.4569L177.218 62.0906Z"
          fill="#626262"
      />
      <path
          d="M189.688 37.2312V70.2306H196.388V37.2312H197.125V70.2306H203.825V37.2312H204.562V70.9937H188.982V37.2312H189.688Z"
          fill="#626262"
      />
      <path
          d="M206.166 45.5331C206.166 40.8156 207.544 37 211.071 37C214.661 37 216.039 40.8156 216.039 45.5331V62.715C216.039 67.4325 214.661 71.2481 211.071 71.2481C207.544 71.2481 206.166 67.4325 206.166 62.715V45.5331ZM206.903 62.7612C206.903 66.9006 207.993 70.5312 211.071 70.5312C214.18 70.5312 215.302 66.9006 215.302 62.7612V45.4869C215.302 41.3475 214.18 37.7169 211.071 37.7169C207.993 37.7169 206.903 41.3475 206.903 45.4869V62.7612Z"
          fill="#626262"
      />
      <path
          d="M218.378 38.0175V70.9937H217.641V37.2312H226.264V37.9943H218.378V38.0175Z"
          fill="#626262"
      />
      <path
          d="M227.867 45.5331C227.867 40.8156 229.246 37 232.772 37C236.362 37 237.741 40.8156 237.741 45.5331V62.715C237.741 67.4325 236.362 71.2481 232.772 71.2481C229.246 71.2481 227.867 67.4325 227.867 62.715V45.5331ZM228.605 62.7612C228.605 66.9006 229.694 70.5312 232.772 70.5312C235.881 70.5312 237.003 66.9006 237.003 62.7612V45.4869C237.003 41.3475 235.881 37.7169 232.772 37.7169C229.694 37.7169 228.605 41.3475 228.605 45.4869V62.7612Z"
          fill="#626262"
      />
      <path
          d="M255.821 45.5331V46.4119C255.821 49.6494 254.57 52.4475 252.455 53.3494C254.699 54.1125 255.821 56.8644 255.821 60.1019V62.715C255.821 67.4325 254.538 71.2481 250.98 71.2481C247.422 71.2481 246.107 67.4325 246.107 62.715V60.2637H246.845V62.7612C246.845 66.8544 247.871 70.5312 250.948 70.5312C254.025 70.5312 255.083 66.8544 255.083 62.715V60.1019C255.083 56.5406 253.513 53.8812 251.076 53.8812H249.57V52.91H251.108C253.416 52.91 255.083 49.8806 255.083 46.5506V45.5331C255.083 41.3475 254.025 37.7169 250.948 37.7169C247.871 37.7169 246.813 41.3475 246.813 45.4869V46.9437H246.139V45.4869C246.139 40.8156 247.422 37 250.98 37C254.538 37 255.821 40.8156 255.821 45.5331Z"
          fill="#626262"
      />
      <path
          d="M270.374 74V70.7625H258.097V74H257.359V69.9994H259.026C260.052 68.4963 260.18 66.4844 260.244 64.2181L261.206 37.2544H269.06V69.9994H271.112V74H270.374ZM260.95 64.2413C260.854 66.4613 260.789 68.4269 259.828 69.9763H268.323V38.0175H261.911L260.95 64.2413Z"
          fill="#626262"
      />
      <path
          d="M272.65 45.5331C272.65 40.8156 274.029 37 277.555 37C281.145 37 282.524 40.8156 282.524 45.5331V62.715C282.524 67.4325 281.145 71.2481 277.555 71.2481C274.029 71.2481 272.65 67.4325 272.65 62.715V45.5331ZM273.388 62.7612C273.388 66.9006 274.478 70.5312 277.555 70.5312C280.665 70.5312 281.787 66.9006 281.787 62.7612V45.4869C281.787 41.3475 280.665 37.7169 277.555 37.7169C274.478 37.7169 273.388 41.3475 273.388 45.4869V62.7612Z"
          fill="#626262"
      />
      <path
          d="M288.71 37.2312C292.301 37.2312 293.519 40.6537 293.519 45.3712V48.7474C293.519 54.1124 291.852 56.6562 288.197 56.6562H284.896V70.9937H284.158V37.2312H288.71ZM288.197 55.9162C291.435 55.9162 292.814 53.6037 292.814 48.8168V45.3481C292.814 41.2087 291.852 38.0175 288.742 38.0175H284.896V55.9162H288.197Z"
          fill="#626262"
      />
      <path
          d="M295.027 45.5331C295.027 40.8156 296.406 37 299.932 37C303.522 37 304.901 40.8156 304.901 45.5331V62.715C304.901 67.4325 303.522 71.2481 299.932 71.2481C296.406 71.2481 295.027 67.4325 295.027 62.715V45.5331ZM295.765 62.7612C295.765 66.9006 296.855 70.5312 299.932 70.5312C303.042 70.5312 304.164 66.9006 304.164 62.7612V45.4869C304.164 41.3475 303.042 37.7169 299.932 37.7169C296.855 37.7169 295.765 41.3475 295.765 45.4869V62.7612Z"
          fill="#626262"
      />
      <path
          d="M311.151 37.2312C314.517 37.2312 315.639 39.6824 315.639 44.2149V46.3887C315.639 50.1581 315.03 52.4706 312.434 53.0487C315.19 53.6268 316.12 56.3324 316.12 60.2406V63.2237C316.12 67.9874 314.742 70.9937 311.215 70.9937H306.535V37.2312H311.151ZM310.254 52.8174C313.459 52.8174 314.902 51.4531 314.902 46.5506V44.2843C314.902 40.3299 314.069 38.0175 311.151 38.0175H307.24V52.8406H310.254V52.8174ZM311.183 70.2306C314.229 70.2306 315.351 67.4787 315.351 63.2237V60.1943C315.351 55.3149 313.748 53.5343 310.574 53.5343H307.24V70.2306H311.183Z"
          fill="#626262"
      />
      <path
          d="M318.363 37.1851L318.299 44.9551H317.69L317.658 37.1851H318.363V37.1851Z"
          fill="#626262"
      />
      <path
          d="M326.41 55.4306C323.397 55.4306 321.345 56.7256 321.345 61.6975V66.9931C321.345 68.45 321.249 69.93 320.768 70.9937H319.967C320.512 69.93 320.608 68.2881 320.608 66.9931V61.6975C320.608 57.9744 321.698 55.5231 324.294 54.945C321.73 54.2744 320.608 52.0081 320.608 47.8456V44.8163C320.608 40.1913 321.922 37.2544 325.384 37.2544H330.001V71.0169H329.263V55.4306H326.41ZM325.416 38.0175C322.435 38.0175 321.345 40.8156 321.345 44.9319V48.0075C321.345 53.2106 323.205 54.6675 326.41 54.6675H329.263V38.0175H325.416V38.0175Z"
          fill="#626262"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "self-med",
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.self-med {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;

  padding: 15px;
  height: $footerSizeSm;
  background: rgba(255, 255, 255, 1);

  @include gt-md {
    height: calc((var(--vh, 1vh) * 15));
  }

  svg {
    display: block;
    width: 100%;
    height: auto;

    &.desk {
      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    &.mob {
      display: none;
      @media screen and (max-width: 500px) {
        display: block;
      }
    }
  }
}
</style>
