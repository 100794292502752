<template>
  <section id="rhinitis-and-urticaria" class="section">
    <div class="section__container">
      <div class="section__content">
        <div class="section__content--wrap">
          <div class="section__content--block1">
            <div class="section__content--title">
              Риніт та кропив’янка —
              <span class="part">найпоширеніші прояви алерг<span style="letter-spacing: 0.08em">ії</span></span>
            </div>

            <div class="section__content--img">
              <div class="section__bg"></div>
            </div>
          </div>

          <div class="section__content--block2">
            <div class="section__content--accordion">
              <div class="item" :class="{ active: state === 0 }">
                <div class="item-label" @click="state = 0">
                  До 22% людей страждали принаймні одним підтипом кропив’янки
                  протягом життя​<sup>9</sup>
                </div>
                <div class="item-content">
                  <p>
                    <strong>Кропив'янка</strong> — це запалення шкіри, що
                    характеризується її почервонінням, появою висипань та
                    набряку після контакту з алергеном<sup>9</sup>
                  </p>
                  <p>
                    <strong>Можливі алергени:</strong> побутова хімія, укуси
                    комах​
                  </p>
                </div>
              </div>

              <div class="item" :class="{ active: state === 1 }">
                <div class="item-label" @click="state = 1">
                  До 20% людей ​має алергічний риніт, і цей відсоток зростає<sup
                >7</sup
                >
                </div>
                <div class="item-content">
                  <p>
                    <strong>Алергічний риніт</strong> — запальна реакція
                    слизової оболонки носа у відповідь на алергени, які є в
                    повітрі<sup>7</sup>
                  </p>
                  <p>
                    <strong>Можливі алергени:</strong> пилок рослин, побутовий
                    пил​
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-bg"></div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      state: 1,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.section {

  &__container {
    padding-bottom: 0;
  }

  @include gt-md {
    padding-bottom: calc((var(--vh, 1vh) * 15) + 40px);
    @media screen and (max-height: 675px) {
      padding-bottom: calc((var(--vh, 1vh) * 15) + 15px);
    }
  }

  &__content {
    width: 100%;
    // height: 100%;
    // max-height: 450px; /* ---- */
    border: 1px solid #eef233; /* ---- */

    // ---
    padding: 15px;

    @include gt-md {
      padding: 50px 55px;
    }


    &--wrap {
      @include gt-md {
        display: flex;
        justify-content: space-between;

        height: 100%;
      }
    }

    &--block1 {
      flex-shrink: 0;
    }

    &--block2 {
      // padding-top: 230px;

      @include gt-md {
        height: 100%;
        // padding-top: 100px;
        width: 530px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    &--title {
      position: relative;
      z-index: 11;

      padding-right: 50px;
      @include Ubuntu-700;
      font-size: 19px;
      @media screen  and (min-width: 390px){
        font-size: 21px;
      }

      @include gt-md {
        font-size: 32px;
      }

      .part {
        @include gt-md {
          display: block;
        }
      }
    }

    &--img {
      position: relative;
      margin: 10px 0;
      height: 210px;
      // background-color: #00000010;
    }

    &--accordion {
      position: relative;
      z-index: 11;

      .item {
        border-top: 1px solid #eef233;

        @include gt-md {
          padding: 25px 0;

          @media screen and (max-height: 620px) {
            padding: 15px 0;
          }
        }

        &.active {
          .item-label::after {
            display: none;
          }

          .item-content {
            max-height: 150px;
          }
        }

        &:last-child .item-label::after {
          transform: rotate(180deg);
        }
      }

      .item-label {
        position: relative;
        padding: 15px 0;
        padding-right: 40px;
        cursor: pointer;

        font-size: 16px;
        font-weight: 600;
        line-height: 130%;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #eef233;

        @include gt-md {
          padding-right: 60px;
          font-size: 20px;
        }

        &::after {
          position: absolute;
          top: 27px;
          right: 12px;
          width: 24px;
          height: 13px;
          background-image: url("@/assets/allergy_icon/arrow_down.svg");
          background-repeat: no-repeat;
          content: "";
        }
      }

      .item-content {
        transition: max-height 1s;
        max-height: 0;
        overflow: hidden;

        font-size: 13px;
        line-height: 130%;

        @media screen  and (min-width: 390px){
          font-size: 15px;
        }

        @include gt-md {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  &__bg {
    position: absolute;
    z-index: 10;

    background-size: 100% 100%;
    background-repeat: no-repeat;

    background-image: url("@/assets/home_icon/img-mob-4.png");
    width: 658px;
    height: 507px;
    top: -100px;
    left: -230px;

    @include gt-md {
      background-image: url("@/assets/allergy_icon/img-des-4.png");
      width: 1769px;
      height: 883px;
      top: -250px;
      left: -520px;
    }
  }
}
</style>
