<template>
  <div id="app" v-bind:class="[{ active: mobile }]">
    <topMenu></topMenu>

    <div class="content-wrapper">
      <router-view/>
    </div>

    <bottomFooter class="fullscreen"></bottomFooter>

    <selfMed></selfMed>

    <cookie-dialog></cookie-dialog>
  </div>
</template>

<script>

document.ontouchmove = function (event) {
  event.preventDefault();
}

import topMenu from "@/components/top-menu";
import bottomFooter from "@/components/bottom-footer";
import selfMed from "@/components/self-med";
import CookieDialog from "@/components/cookie-dialog";

export default {
  components: {
    topMenu,
    bottomFooter,
    selfMed,
    CookieDialog,
  },
  data: () => ({
    mobile: false,
  }),

  methods: {
    updateVH() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },

  mounted() {
    this.updateVH();

    if (window.matchMedia("(min-width: 1024px)").matches) {
      window.addEventListener("resize", this.updateVH);
    }


    this.$nextTick(() => {
      let ua = navigator.userAgent.toLowerCase();
      let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);


      if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        this.mobile = true
      }
      if (navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2 &&
          /MacIntel/.test(navigator.platform)) {
        this.mobile = true
      }

      if (isAndroid || iOS === true) {
        this.mobile = true
      }
    })
  },
};
</script>

<style lang="scss">

body {

}

.section * {
  cursor: default;


}

#interesting-facts {
  .section__content--drag-block * {
    cursor: grab !important;
  }
}

#app {

  &.active {

    .slider-btn {
      -webkit-tap-highlight-color: rgba(255, 0, 0, 0) !important;
      outline: none !important;
      cursor: none !important;

      &:focus, &:active {
        background: rgba(255, 255, 255, 0) !important;
        outline: none !important;
        cursor: none !important;
      }
    }

    * {
      -webkit-tap-highlight-color: rgba(255, 0, 0, 0) !important;
      outline: none !important;
      cursor: none !important;
    }
  }
}

</style>
