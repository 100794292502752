<template>
  <section id="home-section4" class="section">
    <div class="section__container">
      <div class="section__content">
        <div class="section__content--title">
          Алерон блокує рецептори гістаміну, завдяки чому<sup>1</sup>
        </div>

        <ul class="section__content--list">
          <li class="list-item">
            Впливає на перебіг алергічних реакцій<sup>1</sup>
          </li>
          <li class="list-item">Полегшує свербіж<sup>1</sup></li>
          <li class="list-item">
            Зменшує кількість виділень з носа<sup>1</sup>
          </li>
          <li class="list-item">Бореться із запаленням<sup>1</sup></li>
        </ul>

        <div class="section__content--actions">
          <a target="_blank" href="https://tabletki.ua/%D0%90%D0%BB%D0%B5%D1%80%D0%BE%D0%BD/" class="btn" onclick="gtag('event', 'click', {  'event_category': 'Де придбати',  'event_label':'Алерон блокує рецептори гістаміну, завдяки чому'})">
            Де придбати
            <span class="btn__icon btn__icon--arrow"></span>
          </a>
        </div>

        <div class="section__bg"></div>
      </div>
    </div>

    <!-- <div class="section-bg"></div>

    <div class="section-content">
      <div class="section-content__title">
        Алерон блокує рецептори гістаміну, завдяки чому<sup>1</sup>
      </div>

      <ul class="section-content__list">
        <li class="list-item">
          Впливає на перебіг алергічних реакцій<sup>1</sup>
        </li>
        <li class="list-item">Полегшує свербіж<sup>1</sup></li>
        <li class="list-item">Зменшує кількість виділень з носа<sup>1</sup></li>
        <li class="list-item">Бореться із запаленням<sup>1</sup></li>
      </ul>

      <div class="actions">
        <a href="/" class="btn">
          Де придбати
          <svg width="15" height="10" style="margin-left: 20px">
            <use xlink:href="@/assets/defs.svg#arrow-right"></use>
          </svg>
        </a>
      </div>
    </div> -->
  </section>
</template>

<script>
export default {
  name: "section-4",
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.section {
  &__container {
    padding-bottom: 0;
  }

  &__content {
    position: relative;
    padding: 20px;
    padding-bottom: 0;
    width: 100%;
    height: 100%;

    @include gt-md {
      display: flex;

      flex-direction: column;
      justify-content: center;


      @media screen and (max-height: 620px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

    }

    &--title {
      position: relative;
      z-index: 11;

      margin-bottom: 210px;
      padding-right: 20px;

      @include Ubuntu-700;
      font-size: 19px;
      line-height: 120%;

      @media screen and (min-width: 390px) {
        font-size: 21px;
      }

      @include gt-md {
        margin-bottom: 50px;
        max-width: 500px;
        font-size: 32px;
      }
    }

    &--list {
      position: relative;
      z-index: 11;

      list-style: none;
      padding-left: 0;
      margin-bottom: 0px;
      max-width: 220px;

      @include gt-md {
        margin-bottom: 110px;
        display: flex;
        flex-wrap: wrap;
        max-width: 550px;

        @media screen and (max-height: 620px) {
          margin-bottom: 0;
        }
      }

      .list-item {
        position: relative;
        padding: 0 30px 20px;

        font-size: 13px;
        line-height: 130%;

        @media screen and (min-width: 390px) {
          font-size: 15px;
        }

        @include gt-md {
          width: 50%;
          font-size: 18px;
          line-height: 24px;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;

          display: block;
          width: 16px;
          height: 16px;
          background-image: url("@/assets/home_icon/check.svg");
        }

        &:nth-child(1) {
          order: 1;
        }

        &:nth-child(2) {
          order: 3;
        }

        &:nth-child(3) {
          order: 2;
        }

        &:nth-child(4) {
          order: 4;
        }
      }
    }

    &--actions {
      position: relative;
      z-index: 11;

      margin-top: 40px;
      //margin-bottom: 50px;

      @include gt-md {
        //margin: auto 0 0 0;
        @media screen and (max-height: 670px) {
          margin-top: 20px;
        }
      }

      &::after {
        content: "";
        position: absolute;
        z-index: 9;
        background-color: #eef233;
        opacity: 1;

        border-radius: 50%;

        top: -60px;
        left: 120px;
        width: 150px;
        height: 150px;
        filter: blur(60px);

        display: none;

        @include gt-md {
          display: block;
          top: -60px;
          left: 200px;
          width: 200px;
          height: 200px;
          filter: blur(100px);
        }
      }
    }
  }

  &__bg {
    z-index: 10;
    position: absolute;
    display: block;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    background-image: url("@/assets/home_icon/img-mob-2-min.png");
    width: 615px;
    height: 480px;
    top: 0;
    left: -125px;

    @include gt-md {
      background-image: url("@/assets/home_icon/img-des-2.png");
      width: 1654px;
      height: 768px;
      top: 0;
      left: -60px;
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  min-width: 185px;
  padding: 12px 20px;
  background: #eef233;
  color: #0d783d;
  border-radius: 41px;
  text-decoration: none;

  font-weight: 600;
  font-size: 13px;
  line-height: 130%;

  @media screen and (min-width: 390px) {
    font-size: 15px;
  }

  @media screen and (min-width: 1024px) {
    &:hover {
      background-color: #ffffff;
    }
  }

  &:active {
    background-color: #ffffff;
  }

  @include gt-md {
    padding: 13px 20px;
    min-width: 220px;

    font-size: 18px;
    line-height: 24px;
  }

  &__icon {
    margin-left: 20px;

    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;

    width: 14px;
    height: 14px;

    &--arrow {
      width: 16px;
      min-height: 16px;
      background-image: url("@/assets/icon/arrow-right.svg");
    }
  }
}
</style>
