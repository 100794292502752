<template>
  <section id="home-section3" class="section">
    <div class="section__container">
      <div class="section__content" v-scroll="handleScroll">
        <div class="section__content--title">
          Найбільш поширені симптоми алерг<span style="letter-spacing: 0.08em">ії</span><sup>7,8</sup>
        </div>

        <ul class="section__content--list">
          <li class="item item-1">
            <div>Чхання <br/><span class="small">(іноді кашель)</span></div>
          </li>
          <li class="item item-2">Свербіж</li>
          <li class="item item-3">Закладеність носа</li>
          <li class="item item-4">
            <div>
              Почервоніння
              <div>шкіри</div>
              та очей​
            </div>
          </li>
          <li class="item item-5">Сльозотеча</li>
        </ul>

        <div class="section__content--actions">
          <router-link to="/allergy#how-does-an-allergy-occur" class="btn" onclick="gtag('event', 'click', {  'event_category': 'Більше про алергію',  'event_label':'Найбільш поширені симптоми алергії'})">
            Більше про алергію
            <svg
                class="btn__icon btn__icon--arrow"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M9.202 1.116L10.678 0C11.254 0.948 11.818 1.782 12.37 2.502C12.934 3.21 13.51 3.846 14.098 4.41C14.686 4.974 15.316 5.502 15.988 5.994V6.102C15.316 6.582 14.686 7.104 14.098 7.668C13.51 8.232 12.934 8.874 12.37 9.594C11.818 10.302 11.254 11.136 10.678 12.096L9.202 10.962C9.694 10.206 10.198 9.528 10.714 8.928C11.23 8.328 11.746 7.812 12.262 7.38C12.778 6.936 13.282 6.582 13.774 6.318C14.266 6.042 14.734 5.85 15.178 5.742V6.354C14.734 6.246 14.266 6.054 13.774 5.778C13.282 5.502 12.778 5.148 12.262 4.716C11.746 4.272 11.23 3.756 10.714 3.168C10.198 2.568 9.694 1.884 9.202 1.116ZM0 5.076H9.742C10.558 5.076 11.296 5.106 11.956 5.166C12.628 5.226 13.234 5.304 13.774 5.4L14.98 6.048L13.774 6.696C13.234 6.78 12.628 6.852 11.956 6.912C11.296 6.972 10.558 7.002 9.742 7.002H0V5.076Z"
                  fill="currentColor"
              />
            </svg>
          </router-link>
        </div>

        <div class="section__bg"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "section-3",

  methods: {
    handleScroll(evt, el) {
      if (el.getBoundingClientRect().top <= 250) {

        el.classList.add("visible");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.section {
  &__container {
    padding-bottom: 0;
  }

  &__content {
    height: auto;
    padding: 0 20px;

    @include gt-md {
      padding: 0 120px 0;
    }

    &.visible {
      $time: 0.5s;

      .item {
        transform: scale(1);
        transition: transform $time ease-in 0s;
      }

      .item-1 {
        transition-delay: calc(1s + $time * 0);
      }

      .item-3 {
        transition-delay: calc(1s + $time * 1);
      }

      .item-2 {
        transition-delay: calc(1s + $time * 2);
      }

      .item-5 {
        transition-delay: calc(1s + $time * 3);
      }

      .item-4 {
        transition-delay: calc(1s + $time * 4);
      }
    }

    &--title {
      position: relative;
      z-index: 11;

      margin-bottom: 20px;

      @include Ubuntu-700;
      font-size: 19px;
      line-height: 120%;

      @media screen and (min-width: 390px) {
        font-size: 21px;
      }

      @include gt-md {
        width: 570px;
        font-size: 32px;
      }
    }

    &--list {
      position: relative;
      z-index: 11;

      margin: 0;
      padding: 0;
      list-style: none;
      height: 380px;

      @include gt-md {
        @media screen and (max-height: 675px) {
          height: 320px;
        }
        @media screen and (max-height: 620px) {
          height: 270px;
        }
      }


      .item {
        position: absolute;
        transform: scale(0);

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        text-align: center;
        background-color: #ffffff;
        opacity: 0.9;
        color: #0d783d;

        font-weight: 600;
        font-size: 13px;
        line-height: 120%;

        @media screen and (min-width: 390px) {
          font-size: 15px;
        }

        @include gt-md {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .small {
        font-size: 10px;
        font-weight: 400;

        @include gt-md {
          font-size: 14px;
        }
      }

      .item-1 {
        $size: 82px;

        width: $size;
        height: $size;
        top: 50px;
        left: 0;

        @include gt-md {
          $size: 110px;

          width: $size;
          height: $size;
          top: 50px;
          left: 0;

          @media screen and (max-height: 675px) {
            top: 10px;
          }
          @media screen and (max-height: 620px) {
            top: -10px;
          }
        }
      }

      .item-2 {
        $size: 75px;

        width: $size;
        height: $size;
        top: 0px;
        left: calc(225px + 40px);

        @media screen and (max-width: 390px) {
          left: 225px;
        }

        @media screen and (max-width: 340px) {
          left: 190px;
        }

        @include gt-md {
          $size: 95px;

          width: $size;
          height: $size;
          top: 20px;
          left: 520px;

          @media screen and (max-height: 675px) {
            top: 0;
          }
        }
      }

      .item-3 {
        $size: 105px;

        width: $size;
        height: $size;
        top: 200px;
        left: 25px;

        @include gt-md {
          $size: 128px;

          padding-top: 0.5em;

          font-size: 16px;
          width: $size;
          height: $size;
          top: 230px;
          left: 55px;

          @media screen and (max-height: 675px) {
            top: 200px;
          }
          @media screen and (max-height: 620px) {
            top: 140px;
          }
        }
      }

      .item-4 {
        $size: 120px;

        width: $size;
        height: $size;
        top: 265px;
        left: 80px;

        @include gt-md {
          $size: 145px;

          padding-top: 0.5em;
          font-size: 16px;
          width: $size;
          height: $size;
          top: -20px;
          left: 780px;

          @media screen and (max-height: 675px) {
            top: -50px;
          }
        }
      }

      .item-5 {
        $size: 95px;

        width: $size;
        height: $size;
        top: 150px;
        left: calc(210px + 40px);

        @media screen and (max-width: 390px) {
          left: 210px;
        }

        @media screen and (max-width: 340px) {
          left: 180px;
        }

        @include gt-md {
          $size: 125px;

          width: $size;
          height: $size;
          top: 120px;
          left: 650px;

          @media screen and (max-height: 675px) {
            top: 90px;
          }
        }
      }
    }

    &--actions {
      position: relative;
      z-index: 11;

      margin-top: 50px;

      @include gt-md {
        position: absolute;
        left: 910px;
        bottom: 0;

        @media screen and (max-height: 675px) {
          margin-top: 20px;
        }
      }
    }
  }

  &__bg {
    z-index: 10;
    position: absolute;
    display: block;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    background-image: url("@/assets/home_icon/img-mob-1-min.png");
    width: 897px;
    height: 747px;
    top: -40px;
    left: -256px;

    @include gt-md {
      background-image: url("@/assets/home_icon/img-des-1.png");
      width: 1702px;
      height: 994px;
      top: -70px;
      left: -430px;
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  min-width: 185px;

  padding: 10px 15px;

  border: 1px solid #eef233;
  border-radius: 41px;

  color: #eef233;
  text-decoration: none;

  font-weight: 600;
  font-size: 13px;

  @media screen and (min-width: 390px) {
    font-size: 15px;
    min-width: 191px;
  }

  @include gt-md {
    padding: 10px 20px;
    font-size: 18px;
    line-height: 24px;
  }



  @media screen and (min-width: 1024px) {
    &:hover {
      border-color: #ffffff;
      color: #ffffff;
    }
  }
  &:active {
    border-color: #ffffff;
    color: #ffffff;
  }

  &__icon {
    margin-left: 9px;

    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;

    width: 14px;
    height: 14px;

    @include gt-md {
      margin-left: 20px;
    }


    &--arrow {
      width: 16px;
      min-height: 16px;
      background-image: url("@/assets/icon/arrow-right2.svg");
    }
  }
}
</style>
