<template>
  <section id="advices" class="section">
    <div class="section__container">
      <div class="section__content">
        <div class="section__content--block">
          <div>
            <div class="section__content--title">
              Поради для контролю алерг<span style="letter-spacing: 0.08em">ії</span>
            </div>
            <div class="section__content--subtitle">
              Знизити контакт з алергенами<sup>14</sup>
            </div>

            <ul class="section__content--list">
              <li class="list-item item-1">
                Обгортати матрац, подушку, ковдру в непроникні для алергенів
                матеріали​
              </li>
              <li class="list-item item-2">
                Мінімізувати перебування на вулиці в періоди, коли кількість
                пилку в повітрі найбільша (вранці, ввечері)
              </li>
              <li class="list-item item-3">
                Не виходити на вулицю до/після грози​
              </li>
              <li class="list-item item-4">
                Закривати вікна вдома та в автомобілі​
              </li>
              <li class="list-item item-5">
                Не сушити випраний одяг на відкритому повітрі​
              </li>
            </ul>

            <div class="section__content--subtitle">
              Застосовувати протиалергічні засоби<sup>14</sup>
            </div>

            <ul class="section__content--list">
              <li class="list-item large item-6">
                Перед застосовуванням обов'язково проконсультуйтесь з лікарем та
                ознайомтесь з інструкцією для медичного застосування лікарського
                засобу.
              </li>
            </ul>
          </div>
        </div>

        <div class="section__bg"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "section-6",
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.section {

  @include gt-md {
    padding-bottom: calc((var(--vh, 1vh) * 15) + 40px);
    @media screen and (max-height: 675px) {
      padding-bottom: calc((var(--vh, 1vh) * 15) + 15px);
    }
  }

  &__container {
    padding-bottom: 0;
  }

  &__content {
    border: 1px solid #eef233;

    height: calc(100% - 10vh);
    min-height: 100%;

    @include gt-md {
      display: flex;
      padding: 40px 0 25px;
    }

    &--block {
      position: relative;
      z-index: 11;

      margin-left: 0;
      padding: 20px;

      @include gt-md {
        margin-left: 560px;
        padding-right: 40px;
        width: 660px;

        display: flex;
        align-items: center;
      }
    }

    &--title {
      margin-bottom: 220px;

      @include Ubuntu-700;
      font-size: 19px;
      line-height: 120%;

      @media screen and (min-width: 390px) {
        font-size: 21px;
      }

      @include gt-md {
        margin-bottom: 0;
        font-size: 32px;
      }
    }

    &--subtitle {
      margin-top: 30px;
      margin-bottom: 20px;

      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      font-feature-settings: "pnum" on, "lnum" on;
      color: #eef233;
      @media screen and (max-width: 390px) {
        font-size: 16px;
      }

      @include gt-md {
        font-size: 20px;

        @media screen and (max-height: 760px) {
          margin-top: 15px;
          margin-bottom: 10px;
        }
        @media screen and (max-height: 590px) {
          margin-top: 7.5px;
          margin-bottom: 5px;
        }
      }
    }

    &--list {
      margin: 0;
      padding: 0;
      list-style: none;

      @include gt-md {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 240px;
      }

      .list-item {
        position: relative;
        margin-bottom: 20px;
        padding-left: 52px;
        //padding-right: 30px;
        min-height: 36px;

        font-size: 13px;
        line-height: 130%;
        width: 100%;
        font-feature-settings: "pnum" on, "lnum" on;

        @media screen and (min-width: 390px) {
          font-size: 15px;
        }

        @include gt-md {
          font-size: 16px;
          width: 50%;
        }


        &:last-child {
          margin-bottom: 0;
        }

        &.large {
          width: 100%;
          margin-bottom: 10px;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;

          width: 36px;
          height: 36px;

          background-repeat: no-repeat;
        }
      }

      .item-2{
        //margin-bottom: 0;
      }

      .item-1::before {
        background-image: url("@/assets/home_icon/6icon-1.svg");
      }

      .item-2::before {
        background-image: url("@/assets/home_icon/6icon-2.svg");
      }

      .item-3::before {
        background-image: url("@/assets/home_icon/6icon-3.svg");
      }

      .item-4::before {
        background-image: url("@/assets/home_icon/6icon-4.svg");
      }

      .item-5::before {
        background-image: url("@/assets/home_icon/6icon-5.svg");
      }

      .item-6::before {
        width: 46px;
        height: 46px;
        background-image: url("@/assets/home_icon/6icon-6.svg");
      }
    }
  }

  &__bg {
    z-index: 10;
    position: absolute;
    display: block;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    background-image: url("@/assets/home_icon/img-mob-3-min.png");
    width: 787px;
    height: 572px;

    top: -40px;
    left: -170px;

    @include gt-md {
      background-image: url("@/assets/home_icon/img-des-3.png");
      width: 1610px;
      height: 768px;

      // top: -70px;
      top: calc(50% - 350px);
      left: -320px;
    }
  }
}
</style>
