<template>
  <section id="alleron-in-action" class="section">
    <div class="section__container">
      <div class="section__content">
        <div class="section__content--block1">
          <div>
            <div class="section__content--title">
              Алерон – це протиалергійний засіб другого покоління<sup>4</sup>
            </div>

            <ul class="section__content--list">
              <li class="list-item item-1">
                <span class="sp">
                  Менша вірогідність впливу на розумову діяльність
                </span>
                та пов'язане з ним погіршення працездатності порівняно з
                антигістамінними засобами 1-го покоління<sup>4</sup>
              </li>

              <li class="list-item item-2">
                <span class="sp">Менша вираженість седативного ефекту</span>
                порівняно з антигістамінними засобами 1-го покоління<sup>4</sup>
              </li>

              <li class="list-item item-3">
                <span class="sp">Починає діяти</span> через 45 хвилин – 1&nbsp;годину<sup>5</sup>
              </li>

              <li class="list-item item-4">
                Зберігає протиалергійний
                <span class="sp">ефект протягом 24 годин<sup>2,3,6*</sup></span>
              </li>

              <li class="list-item item-5">
                <span class="sp">Взаємодія з іншими</span> лікарськими засобами
                малоймовірна<sup>1</sup>
              </li>
            </ul>
          </div>
        </div>

        <div class="section__content--block2">
          <div class="block-wrapper">
            <div class="section__bg"></div>

            <div class="section__content--info">
              <div>
                Відповідно до інструкц<span style="letter-spacing: 0.08em">ії</span> для медичного застосування пацієнтам
                слід:
              </div>

              <ul class="section__content--simple-list">
                <li>
                  уникати застосування седативних засобів під час прийому
                  препарату<sup>1</sup>
                </li>
                <li>
                  утримуватися від керування автотранспортом або роботи з іншими
                  потенційно небезпечними механізмами під час лікування
                  препаратом<sup>1</sup>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "section-2",
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.section {
  &__container {
    padding-bottom: 0;
  }

  @include gt-md {
    padding-bottom: calc((var(--vh, 1vh) * 15) + 40px);
    @media screen and (max-height: 675px) {
      padding-bottom: calc((var(--vh, 1vh) * 15) + 15px);
    }
  }

  &__content {
    border: 1px solid #eef233; /* ---- eef233 */
    height: calc(100%);

    @include gt-md {
      padding: 25px 0;
      display: flex;
    }

    &--block1 {
      position: relative;
      z-index: 11;

      order: 2;
      padding: 25px 20px 10px;

      @include gt-md {
        padding: 10px 55px;
        width: 700px;
        border-left: 1px solid #eef233;

        display: flex;
        align-items: center;
      }
    }

    &--block2 {
      order: 1;
      display: flex;
      flex-shrink: 0;
      padding: 20px 20px 10px;

      @include gt-md {
        padding: 25px 35px;
        width: 500px;

        display: flex;
        align-items: center;
      }

      .block-wrapper {
        @include gt-md {
          position: relative;
        }
      }
    }

    &--title {
      margin-bottom: 210px;

      @include Ubuntu-700;
      font-size: 19px;
      line-height: 120%;
      color: #ffffff;

      @media screen and (min-width: 390px) {
        font-size: 21px;
      }

      @include gt-md {
        margin-bottom: 25px;
        font-size: 32px;

        @media screen and (max-height: 675px) {
          margin-bottom: 15px;
        }
      }
    }

    &--list {
      margin: 0;
      padding: 0;
      list-style: none;

      .list-item {
        position: relative;
        margin-top: 1.7em;
        padding-left: 66px;
        //padding-right: 30px;
        width: 100%;


        font-size: 13px;
        line-height: 132%;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #ffffff;

        @media screen and (min-width: 390px) {
          font-size: 15px;
        }

        @include gt-md {
          font-size: 18px;
          line-height: 24px;
          max-width: initial;

          @media screen and (max-height: 675px) {
            margin-top: 1em;
          }
        }

        .sp {
          color: #eef233;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0px;
          width: 45px;
          height: 45px;
        }
      }

      .item-1::before {
        background: url("@/assets/home_icon/icon-1.svg") no-repeat;
        background-size: 45px;
      }

      .item-2::before {
        background: url("@/assets/home_icon/icon-2.svg") no-repeat;
        background-size: 45px;

      }

      .item-3::before {
        top: -8px;
        background: url("@/assets/home_icon/icon-3.svg") no-repeat;
        background-size: 45px;

      }

      .item-4::before {
        top: -8px;
        background: url("@/assets/home_icon/icon-5.svg") no-repeat;
        background-size: 45px;

      }

      .item-5::before {
        top: -8px;
        background: url("@/assets/home_icon/icon-4.svg") no-repeat;
        background-size: 45px;
      }
    }

    &--info {
      position: relative;
      z-index: 11;

      margin-top: 0;
      font-weight: 300;
      font-size: 11px;
      line-height: 15px;
      color: #ffffff;

      @include gt-md {
        margin-top: 320px;
        font-size: 15px;
        @media screen and (max-height: 675px) {
          margin-top: 250px;
          font-size: 13px;
        }
      }
    }

    &--simple-list {
      margin-top: 0;
      padding-left: 20px;

      li {
        margin-top: 12px;
        line-height: 132.69%;

      }
    }
  }

  &__bg {
    z-index: 10;
    position: absolute;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;

    background-image: url("@/assets/home_icon/packs-mob-2-min.png");
    width: 558px;
    height: 485px;
    top: -70px;
    left: -214px;

    @include gt-md {
      background-image: url("@/assets/home_icon/packs-des-2-min.png");
      width: 943px;
      height: 817px;
      top: -220px;
      left: -400px;
      @media screen and (max-height: 675px) {
        width: calc(943px - 40%);
        height: calc(817px - 40%);
        top: -170px;
        left: -290px;
      }
    }
  }
}
</style>
