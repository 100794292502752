<template>
  <section id="how-does-an-allergy-occur" class="section">
    <div class="section__container">
      <div class="section__content">
        <div class="section__content--block1">
          <div class="section__content--title">Як виникає алергія?</div>
          <img
              class="section__content--gif"
              src="@/assets/gif02.gif"
              alt="gif"
          />
        </div>

        <div class="section__content--block2">
          <div class="section__content--subtitle">
            Алергія — це реакція імунної системи організму на алерген<sup
          >7</sup
          >
          </div>

          <ul class="section__content--list">
            <li class="item">
              <span class="mark">Алерген</span>
              потрапляє в тіло людини​ (шерсть тварин, цвіль, пилок, тверді
              часточки у повітрі)
            </li>
            <li class="item">
              Утворюються <span class="mark">іммуноглобуліни Е,</span> які
              починають взаємодіяти з алергеном​
            </li>
            <li class="item">
              Відбувається вивільнення
              <span class="mark">гістаміну</span> (медіатор запалення)
            </li>
            <li class="item">
              Розширюються <span class="mark">артеріоли,</span> підвищується
              проникність судин
            </li>
            <li class="item">
              Розвиваються <span class="mark">симптоми</span> алерг<span style="letter-spacing: 0.08em">ії</span>, такі як
              свербіж чи ринорея
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "section-1",
};
</script>

<style scoped lang="scss">
@import "@/css/vars";

.section {
  margin-top: 50px;
  // padding-bottom: $footerSizeSm;

  @include gt-md {
    margin-top: $headerSize;
    padding-bottom: calc((var(--vh, 1vh) * 15) + 40px);

    @media screen and (max-height: 700px) {
      padding-bottom: calc((var(--vh, 1vh) * 15) + 15px);
    }
  }

  &__container {
    padding-bottom: 0;
  }

  &__content {
    padding: 15px;
    border: 1px solid #eef233;

    min-height: max-content;

    @include gt-md {
      display: flex;
      flex-direction: row;
      padding: 40px 0;
    }

    &::before {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 100%;
      transform: translateX(-50%);

      width: 300px;
      height: 300px;
      border-radius: 50%;
      content: "";

      background: #eef233;
      opacity: 0.6;
      filter: blur(100px);

      @include gt-md {
        top: 50%;
        left: 0;
        width: 400px;
        height: 400px;
        filter: blur(150px);
        transform: translate(-50%, -50%);
      }
    }

    &--block1 {
      position: relative;
      z-index: 11;

      padding: 0;

      @include gt-md {
        position: relative;
        //padding-top: 25px;
        padding-left: 50px;
        padding-right: 35px;
        padding-bottom: 25px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    &--block2 {
      position: relative;
      z-index: 11;

      padding: 0;

      flex: 1 1 auto;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include gt-md {
        padding-left: 35px;
        padding-right: 45px;
        border-left: 1px solid #eef233;
      }
    }

    &--gif {
      display: block;
      margin: 0 auto;
      min-width: 220px;
      width: 100%;
      height: auto;

      @include gt-md {
        width: 442px;
        height: 384px;

        @media screen and (max-height: 680px) {
          max-width: 380px;
          height: auto;
        }
        @media screen and (max-height: 600px) {
          max-width: 330px;
        }
      }
    }

    &--title {
      @include Ubuntu-700;
      font-size: 19px;
      text-align: center;

      @media screen and (min-width: 390px) {
        font-size: 21px;
      }

      @include gt-md {
        // position: absolute;
        // top: -5px;
        // left: 0;

        width: 100%;
        font-size: 32px;
      }
    }

    &--subtitle {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 600;
      color: #eef233;

      @include gt-md {
        font-size: 20px;
        margin-top: 10px;

        margin-bottom: 33px;
        @media screen and (max-height: 680px) {
          margin-bottom: 15px;
          margin-top: 5px;
        }
      }
    }

    &--list {
      margin: 0;
      padding: 0;
      list-style: none;

      .item {
        position: relative;
        margin-bottom: 15px;
        padding-left: 33px;
        font-size: 15px;
        line-height: 130%;
        min-height: 49px;

        @media screen and (max-width: 390px) {
          font-size: 13px;
        }

        @include gt-md {
          margin-bottom: 40px;
          font-size: 18px;
          line-height: 24px;
          min-height: 49px;

          @media screen and (max-height: 680px) {
            margin-bottom: 15px;
          }
        }

        &::before {
          position: absolute;
          top: 2px;
          left: 0;
          width: 18px;
          height: 49px;
          background-image: url("@/assets/icon/union.svg");
          content: "";

          @include gt-md {
            top: 4px;
          }
        }

        &:last-child {
          margin-bottom: 15px;
          min-height: 0;

          &::before {
            height: 18px;
            background-image: url("@/assets/icon/union2.svg");
          }
        }
      }
    }

    .mark {
      font-weight: 500;
      color: #eef233;
    }
  }
}
</style>
